import { defineStore } from 'pinia'
import { CONFIG_KEY } from "@utils/constants";

export const useConfigStore = defineStore(CONFIG_KEY, () => {
  const publicConfig = ref([]);
  const setPublicConfig = (value) => {
    publicConfig.value = value;
  }

  return {
    publicConfig, setPublicConfig,
  }
})
