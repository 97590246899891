<template>
  <div class="h-auto grid place-items-center p-4 sm:p-8">
    <div class="text-center">
      <p class=" text-body-dark text-xl sm:text-2xl uppercase tracking-widest mb-4 sm:mb-5">
        {{ $t('text-reference-unavailable-title') }}
      </p>
      <h1 class="font-bold text-md leading-normal sm:text-normal text-bolder mb-5">
        {{ $t('text-reference-unavailable-subtitle') }}
      </h1>
      <div class="mb-11">
        <Image
          src="/no-result.svg"
          :alt="$t('404-heading')"
          :width="600"
          :height="453"
        />
      </div>
      <Link
        :href="translatePath(ROUTES.CODE.HOME)"
        class="inline-flex items-center sm:text-base text-bolder underline focus:outline-none hover:no-underline hover:text-body-dark"
        @click="closeModal"
      >
        {{ $t('404-back-home') }}
      </Link>
    </div>
  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import Image from "@components/ui/image";
import Link from "@components/ui/link/link";

const { $eventBus } = useNuxtApp();

const closeModal = () => {
    $eventBus.emit('modal:close');
}

</script>
