import _ from 'lodash-es';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$router.beforeEach((to, from, next) => {
    try {
      if (_.isUndefined(to) || (_.isObject(to) && _.isUndefined(_.get(to, 'path'))) || to === undefined || to === 'undefined') {

        sentryCaptureEvent({
          message: 'route-checker.to',
          level: 'error',
          extra: { from, to }
        });

        next({ path: '/' });
      } else {
        next();
      }
    } catch (error) {
      sentryCaptureEvent({
        message: 'route-checker.catch',
        level: 'error',
        extra: { from, to, error }
      });

      next();
    }
  });
});
