import { defineStore } from 'pinia'
import { UI_KEY } from "@utils/constants";

export const useUIStore = defineStore(UI_KEY, () => {

  const modalView = ref('');
  const setModalView = (value) => {
    modalView.value = value;
  }

  const displayModal = ref(false);
  const setDisplayModal = (value) => {
    displayModal.value = value;
  }

  const sidebarView = ref('');
  const setSidebarView = (value) => {
    sidebarView.value = value;
  }

  const displaySidebar = ref(false);
  const setDisplaySidebar = (value) => {
    displaySidebar.value = value;
  }

  const displayHeaderSearch = ref(false);
  const setDisplayHeaderSearch = (value) => {
    displayHeaderSearch.value = value;
  }

  const displayMobileSearch = ref(false);
  const setDisplayMobileSearch = (value) => {
    displayMobileSearch.value = value;
  }
  const toggleMobileSearch = () => {
    displayMobileSearch.value = !displayMobileSearch.value;
  }

  const displayModalStickyBar = ref(false);
  const setDisplayModalStickyBar = (value) => {
    displayModalStickyBar.value = value;
  }

  return { 
    modalView, setModalView,
    displayModal, setDisplayModal,
    sidebarView, setSidebarView,
    displaySidebar, setDisplaySidebar,
    displayHeaderSearch, setDisplayHeaderSearch,
    displayMobileSearch, setDisplayMobileSearch, toggleMobileSearch,
    displayModalStickyBar, setDisplayModalStickyBar
  }
})
