export default function () {
  const width = ref(import.meta.client && window ? window.innerWidth : Infinity);
  const height = ref(import.meta.client && window ? window.innerHeight : Infinity);

  const updateSize = () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  };

  onMounted(() => {
    window.addEventListener('resize', updateSize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateSize);
  });

  return { width, height, updateSize };
}
