import { API_ENDPOINTS } from "@utils/api/endpoints";
import { PRODUCT_SUBTYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected'

export default async function (idAuction, body) {
  const auth = useAuthStore();
  const store = useSelectedStore();

  if (!auth.isLoggedIn || !auth.idSupplier) {
    return;
  }

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_AUCTION_BID, {
    method: 'POST',
    path: { idAuction },
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'auction-bid-create',
      level: 'error',
      extra: { error: error.value }
    });

    return error.value;
  }

  store.setLoadReset(PRODUCT_SUBTYPE.main, true);
  store.setLoadReset(PRODUCT_SUBTYPE.category, true);
  store.setLoadReset(PRODUCT_SUBTYPE.auction, true);

  return data.value;
}
