import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const auth = useAuthStore();
  const customer = useCustomerStore();

  if (auth.isLoggedIn && !auth.idSupplier) {
    const supplier = await createSupplier({
      billingAddress: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        city: customer.city || ''
      },
      deliveryAddress: {
        firstName: customer.firstName,
        lastName: customer.lastName,
        city: customer.city || ''
      },
      name: customer.fullName
    });
    if (!supplier) {
      return;
    }
    auth.setIdSupplier(supplier.id);
    supplier.distanceKm = 0;
    customer.setSupplier(supplier);
  }
}
