import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';

export default async function (idAuction, params = []) {
  const auth = useAuthStore();

  if (!auth.isLoggedIn || !auth.idSupplier) {
    return;
  }

  const { error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_AUCTION_STATUS, {
    method: 'PUT',
    path: { idAuction },
    params
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'auction-status-update',
      level: 'error',
      extra: { error: error.value }
    });

    return false;
  }

  return true;
}
