<template>
  <button
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors bg-primary-dark hover:text-primary-dark hover:bg-accent-dark-hover focus:text-accent-dark-hover focus:bg-primary-dark-hover focus:ring-primary-dark-hover"
    @click="onClick"
  >
    {{ $t('text-view') }}
  </button>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { STATUS } from "@utils/constants";
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';

const props = defineProps({
  variant: {
    type: Object,
    required: true
  }
});

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const ui = useUIStore();

const handleProductQuickView = async (action = null) => {
  if (ui.displayModal) {
    $eventBus.emit('modal:close');
  }
  nextTick(async () => {
    if (_.get(props.variant, 'product.status', STATUS.DRAFT) !== STATUS.ACTIVE) {
      await navigatorTo(translatePath(ROUTES.CODE.AD_EDIT, props.variant?.product?.id));
    } else {
      store.setAction(action);
      store.setProduct(props.variant);
      $eventBus.emit('modal:open', 'PRODUCT_DETAILS');
    }
  });
};

const onClick = async () => {
  if (ui.displayModal) {
    $eventBus.emit('modal:close');
    await navigatorTo(translatePath(ROUTES.CODE.PRODUCT, slugify(props.variant.id, props.variant.name)));
  } else {
    await handleProductQuickView();
  }
};

</script>
