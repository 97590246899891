<template>
    <SidebarWrapper>
      <ul class="flex-grow">
        <li v-for="({routeCode, label}) in siteSettings.authorizedLinks" :key="`${routeCode}_${label}`">
          <span
            class="block py-3 px-5 md:px-8 text-sm font-semibold text-heading transition duration-200 hover:text-accent hover:underline cursor-pointer"
            @click="() => handleClick(routeCode)"
          >
            {{ $t(label) }}
          </span>
        </li>
      </ul>
    </SidebarWrapper>
</template>

<script setup>
import { siteSettings } from "@settings/site.settings";
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";

const { $eventBus } = useNuxtApp();

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
}

const handleClick = async (routeCode) => {
    closeSidebar();
    await navigatorTo(translatePath(routeCode));
}
</script>
