import { defineStore } from 'pinia'
import { ENUM_KEY } from "@utils/constants";

export const useEnumStore = defineStore(ENUM_KEY, () => {
  const loading = useState('categories.loading', () => false);
  const setLoading = (value) => {
    loading.value = value;
  }
  
  const countries = useState('vars.countries', () => []);
  const setCountries = (value) => {
    countries.value = value;
  }
  
  const currencies = useState('vars.currencies', () => []);
  const setCurrencies = (value) => {
    currencies.value = value;
  }
  
  const availabilities = useState('vars.availabilities', () => []);
  const setAvailabilities = (value) => {
    availabilities.value = value;
  }
  
  const abandonmentReasons = useState('vars.abandonmentReasons', () => ({ buyer: [], seller: [] }));
  const setAbandonmentReasons = (type, value) => {
    abandonmentReasons.value[type] = value;
  }
  
  const languages = useState('vars.languages', () => []);
  const setLanguages = (value) => {
    languages.value = value;
  }
  
  const parameters = useState('vars.parameters', () => []);
  const setParameters = (value) => {
    parameters.value = value;
  }
  
  const banners = useState('vars.banners', () => []);
  const setBanners = (value) => {
    banners.value = value;
  }
  
  return { 
    loading, setLoading,
    countries, setCountries,
    currencies, setCurrencies,
    availabilities, setAvailabilities,
    abandonmentReasons, setAbandonmentReasons,
    languages, setLanguages,
    parameters, setParameters,
    banners, setBanners
  }
})
