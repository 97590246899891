import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useEnumStore } from '@stores/enum';

export default async function () {
  const store = useEnumStore();
  const routeLang = useRouteLang();

  if (store?.countries?.length) return;

  store.setLoading(true);
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.COUNTRY, {
    params: {
      locale: routeLang.locale.value
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-countries',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    store.setCountries(data.value);
  }
  store.setLoading(false);
}
