export default function (file, type = null) {
  const { $eventBus } = useNuxtApp();

  file.url = URL.createObjectURL(file);
  const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxWidth = 1920;
        const scaleSize = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleSize;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const content = canvas.toDataURL('image/jpeg', 0.8);
        file.encoded = content.substring(content.indexOf(',') + 1);

        $eventBus.emit('file:loaded', {file, type});
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
}
