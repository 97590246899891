<template>
  <button
    :aria-pressed="active"
    :data-variant="variant"
    :class="classesName"
    :disabled="disabled"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script setup>

const props = defineProps({
  className: {
    type: String,
    default: ''
  },
  variant: {
    type: String,
    default: 'outline' 
  },
  size: {
    type: String,
    default: 'big' 
  },
  active: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  onClick: {
    type: Function,
    default: () => { }
  }
});

const classes = {
  root: 'inline-flex items-center justify-center rounded-xl transition-colors',
  base: 'text-light border border-primary-dark hover:border-primary-dark-hover hover:text-primary-dark bg-primary-dark hover:bg-transparent',
  simple: 'text-light hover:text-accent-dark-hover',
  accent: 'text-primary-dark border border-accent-dark hover:border-accent-dark-hover hover:text-light bg-accent-dark hover:bg-primary-dark-hover',
  outline: 'text-light border border-accent-dark hover:border-accent-dark-hover hover:bg-accent-dark-hover hover:text-primary-dark-hover',
  category: 'text-light border border-accent-dark hover:border-accent-light-hover focus:border-accent-light-hover hover:text-accent-dark-hover focus:text-accent-dark-hover hover:underline focus:underline',
  disabled: 'border border-border-base bg-gray-300 border-border-400 text-body cursor-not-allowed',
  big: 'w-10 h-10 flex-shrink-0',
  full: 'w-full h-full',
  jumbo: 'w-full sm:w-74 h-full',
};

const classesName = computed(() => {
    return [
        classes.root,
        !props.disabled && props.variant === 'base' ? classes.base : '',
        props.disabled && props.variant === 'base' ? classes.disabled : '',
        !props.disabled && props.variant === 'simple' ? classes.simple : '',
        props.disabled && props.variant === 'simple' ? classes.disabled : '',
        !props.disabled && props.variant === 'accent' ? classes.accent : '',
        props.disabled && props.variant === 'accent' ? classes.disabled : '',
        !props.disabled && props.variant === 'outline' ? classes.outline : '',
        props.disabled && props.variant === 'outline' ? classes.disabled : '',
        !props.disabled && props.variant === 'category' ? classes.category : '',
        props.disabled && props.variant === 'category' ? classes.disabled : '',
        props.size === 'big' ? classes.big : '',
        props.size === 'full' ? classes.full : '',
        props.size === 'jumbo' ? classes.jumbo : '',
        props.className
    ];
});

</script>
