import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { appBaseUrl, sentry } } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: sentry.tracesSampleRate,

    tracePropagationTargets: ['localhost', appBaseUrl],

    replaysSessionSampleRate: sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
  })
})
