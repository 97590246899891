
<template>
  <div class="w-full">
    <ChatMessages 
      :parent="content" 
      :type="CHAT_MESSAGE_TYPE.CONTENT" 
      :fetch-url="fetchUrl"
      load-error="error.content-detail.load.comments"
      post-error="error.content-detail.add.comment"
      :is-add-blocked="isAddBlocked"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CHAT_MESSAGE_TYPE } from "@utils/constants";
import { useCustomerStore } from '@stores/customer';
import ChatMessages from "@components/chat/chat-messages";

const props = defineProps({
  content: {
    type: Object,
    default: () => {}
  }
});

const config = useRuntimeConfig();
const customer = useCustomerStore();

const fetchUrl = computed(() => config?.public?.appBaseUrl + API_ENDPOINTS.CONTENT_POST_ID_MESSAGE.replace('{idPost}', props.content.id));

const isAddBlocked = computed(() => {
  return !!_.chain(customer)
    .get('supplier.blockedBy', [])
    .filter({ id: props?.content?.supplier?.id })
    .value()
    .length;
});

</script>
