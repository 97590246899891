import { LANGUAGE_TO_LOCALE } from "@/utils/language-to-locale";

export default function () {
  const route = useRoute();
  const code = useState('routeLang.code', () => null);
  const locale = useState('routeLang.locale', () => null);

  const update = (value) => {
    const routeCode = (value || 'cs').slice(-2);
    if (routeCode !== code.value) {
      code.value = routeCode;
      locale.value = LANGUAGE_TO_LOCALE[code.value] || 'cs_CZ';
    }
  }

  watch(() => route.name, update);

  update(route.name);

  return { code, locale };
}
