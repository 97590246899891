import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const auth = useAuthStore();
  const customer = useCustomerStore();

  if (!auth.isLoggedIn || !auth.idSupplier) {
    return;
  }

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.SUPPLIER_ID, {
    method: 'GET',
    path: {
      id: auth.idSupplier
    },
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-supplier',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  } else {
    if (data.value) {
      data.value.distanceKm = 0;
    }
    customer.setSupplier(data.value);
  }

  return data.value;
}
