<template>
    <div :class="className">
      <label
        v-if="label"
        :htmlFor="name"
        class="block text-body-dark font-semibold text-sm leading-none mb-3 truncate"
      >
        {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
      </label>
      <textarea
        :id="name"
        ref="inputField"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :class="[ 'px-4 py-3 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm placeholder-gray-500 focus:outline-none focus:ring-0', shadow ? 'focus:shadow' : '', variantClasses[variant], error ? '!border-red-500' : '', inputClassName ]"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        :rows="rows"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
    />
      <p v-if="error" class="my-2 text-xs text-red-500">
        {{ error }}
      </p>
    </div>
</template>

<script setup>
defineOptions({
  inheritAttrs: false
});

const variantClasses = {
  normal:
    "bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border border-border-100 focus:bg-light focus:border-accent",
  outline: "border border-border-base focus:border-accent",
};

defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    inputClassName: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    rows: {
        type: Number,
        default: 4
    },
    error: {
        type: String,
        default: ''
    },
    shadow: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline"
    }
});

const { $eventBus } = useNuxtApp();
const inputField = ref(null);

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
    }
  });
};

onMounted(async () => {
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:error', focusError);
});

</script>
