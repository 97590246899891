<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">
    <ConfirmationCard
      :title="$t('text-supplier-image-delete-title')"
      description=""
      :confirm-btn-loading="loading"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import { useSelectedStore } from '@stores/selected'
import ConfirmationCard from "@components/common/confirmation-card";

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const store = useSelectedStore();
const loading = ref(false);

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleSubmit = async () => {
  loading.value = true;
  const result = await supplierImageDelete(store.image.id);
  if (result) {
    $toast.success(t('success.supplier.image.delete'));
    $eventBus.emit('supplier:images:remove', store.image.id);
  } else {
    loading.value = false;
    if (import.meta.client) {
      $toast.error(t('error.supplier.image.delete'));
    }
  }
  closeModal();
}

const handleCancel = () => {
  closeModal();
}

</script>
