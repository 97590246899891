<template>
  <div v-if="bids.items.length" class="w-full">
    <AuctionSelectWinner v-if="auth.idSupplier === variant?.product?.supplier?.id" :variant="variant" :auction="variant.auction" :bids="bids" class="w-full mb-8" />
    <AuctionWinner :auction="variant.auction" class="w-full mb-8" />
    <div class="w-full">
      <h2 class="text-base text-primary-dark font-bold pb-3">
        {{ $t('text-last-auction-bids') }}
      </h2>
      <div
        v-for="(item, idx) in bids.items"
        :key="item.id"
        :class="[ 'w-full flex', auth.idCustomer === item?.customer?.id ? 'justify-end' : 'justify-start' ]"
      >
        <RightAuctionBid
          v-if="auth.idCustomer === item?.customer?.id"
          :variant="variant"
          :item="item"
          :prev-item="prevItem(idx)"
          :selected="selected"
        />
        <LeftAuctionBid
          v-else
          :variant="variant"
          :item="item"
          :prev-item="prevItem(idx)"
          :selected="selected"
        />
      </div>
      <div v-if="loading" class="w-full flex justify-center items-center h-48 bg-transparent relative">
          <Spinner :text="$t('common:text-loading')" />
      </div>
      <div v-if="hasNextPage || loadingMore" class="flex justify-center mt-8 lg:mt-12">
        <Button
            :loading="loadingMore"
            :disabled="loadingMore"
            class-name="text-sm md:text-base font-semibold h-11"
            @click="handleLoadMore"
        >
            {{ $t("text-show-all") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import Spinner from "@components/ui/loaders/spinner/spinner";
import Button from "@components/ui/button";
import LeftAuctionBid from "@components/auction/left-auction-bid";
import RightAuctionBid from "@components/auction/right-auction-bid";
import AuctionWinner from "@components/auction/auction-winner";
import AuctionSelectWinner from "@components/auction/auction-select-winner";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  }
});

const auth = useAuthStore();

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const loading = ref(false);
const loadingMore = ref(false);
const selected = ref(null);
const bids = ref({ items: [] });
const pagination = ref({ page: 1, pageCount: 1, itemsPerPage: 15, itemCount: 0 });

const addBid = (data) => {
  if (!_.find(bids.value?.items || [], { id: data.id })) {
    if (auth.idCustomer === data.customer.id) {
      data.insertedAt = null;
    }
    bids.value.items.unshift(data);
  }
}

const hasNextPage = computed(() => pagination.value.page < pagination.value.pageCount);

const prevItem = (idx) => idx ? bids.value.items[idx - 1] : ({ });

const reload = async (pending = loading) => {
  pending.value = true;
  try {
    const data = await $fetch(API_ENDPOINTS.ESHOP_PRODUCT_VARIANT_AUCTION_BID.replace('{idAuction}', props.variant?.auction?.id), {
      method: 'GET',
      params: {
        page: pagination.value.page,
        limit: pagination.value.itemsPerPage
      }
    });
    bids.value.items.push(...data?.items || []);
    pagination.value = data?.pagination || pagination.value;
  } catch (error) {
    if (import.meta.client) {
      $toast.error(t('error.auction-bid.load'));
    }
    Sentry.captureException(error);
  }
  pending.value = false;
}

const handleLoadMore = async () => {
  pagination.value.page++;
  pagination.value.itemsPerPage = pagination.value.itemCount;
  await reload(loadingMore);
}

const scrollToSelected = () => nextTick(() => {
  const element = document.getElementById(`bid-${selected.value}`);
  if (element) {
    window.scrollTo({ top: (element?.offsetTop || 0) - 240, behavior: "smooth" });
  }
});

const handleLoadSelected = async () => {
  while (import.meta.client && selected.value && !_.find(bids.value?.items || [], { id: selected.value }) && (pagination.value.page < pagination.value.pageCount)) {
    await handleLoadMore();
  }
  scrollToSelected();
};

onMounted(async () => {
  if (import.meta.server || navigator?.onLine) {
    await reload();
  }
  if (import.meta.client && window?.location?.hash && window?.location?.hash.startsWith('#bid-')) {
    selected.value = parseInt(window?.location?.hash.slice(5));
    await handleLoadSelected();
  }
});

const auctionBidAdd = (data) => {
  if (data.id === props.variant?.auction?.id) {
    addBid(data.bid);
    selected.value = data.bid.id;
  }
};

onMounted(async () => {
  $eventBus.on('auction:bid:add', auctionBidAdd);
});

onUnmounted(() => {
  $eventBus.off('auction:bid:add', auctionBidAdd);
});

</script>
