export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const path = document.location.pathname;
  const match = path.match(/^\/([a-z]{2})(\/|$)/);
  const language = match ? match[1] : 'cs';

  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && window.google) {
      resolve(window.google);
      return;
    }

    const preconnectLink = document.createElement('link');
    preconnectLink.rel = 'preconnect';
    preconnectLink.href = 'https://maps.googleapis.com';
    document.head.appendChild(preconnectLink);

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.public.placesApiKey}&libraries=places,marker&language=${language}`;
    script.async = true;
    script.onload = () => resolve(window.google);
    document.head.appendChild(script);
  });
});
