<template>
  <div class="text-right">
    <button 
      class="text-xs font-normal text-primary-dark underline transition-colors hover:text-primary-dark-hover focus:text-primary-dark-hover hover:no-underline focus:no-underline whitespace-nowrap" 
      @click="reportVariant"
    >
      {{ $t('text-report-inspiration') }}
    </button>
  </div>
</template>

<script setup>
import { useUIStore } from '@stores/ui';
import { useSelectedStore } from '@stores/selected';

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  }
});

const ui = useUIStore();
const store = useSelectedStore();
const { $eventBus } = useNuxtApp();

const reportVariant = () => {
  if (ui.modalView && ui.displayModal) {
    store.setAction('RETURN_TO_CONTENT_MODAL');
    $eventBus.emit('modal:close');
  }
  store.setProduct(props.variant);
  $eventBus.emit('modal:open', 'REPORT_SUSPICIOUS_CONTENT');
}

</script>
