import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';

export default async function (id, quantity) {
  const cart = useCartStore();
  const item = cart.getItem(id);
  const store = useSelectedStore();

  if (item) {
    store.setAction(null);
    item.quantity = quantity;
    item.sum = item.quantity * item?.price;
    item.sumNet = item.quantity * item?.priceNet;

    const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
      method: 'PUT',
      params: {
        idProductVariant: id,
        quantity
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'cart-update-item-in-cart',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      cartAddItem(data.value);
    }
  }

}
