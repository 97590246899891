export default function () {
  const { $dayjs } = useNuxtApp();
  const value = ref($dayjs());
  const interval = ref(null);

  onMounted(() => {
    interval.value = setInterval(() => {
      value.value = $dayjs();
    }, 1000);
  });

  onUnmounted(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });
    
  return value;
}
