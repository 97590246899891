import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (body) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTACT_FORM, {
    method: 'POST',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'send-contact-form',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
