<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <Toaster />
    <WsClient />
    <Notifications />
    <ManagedModal />
    <SidebarContainer />
  </div>
</template>

<script setup>
import { useUIStore } from '@stores/ui';
import { useCustomerStore } from '@stores/customer';
import ManagedModal from "@components/ui/modal/managed-modal";
import SidebarContainer from "@components/common/sidebar/sidebar-container";
import Notifications from "@components/ui/toast/notifications";
import Toaster from "@components/common/toaster";
import WsClient from "@components/common/events/ws-client";

const ui = useUIStore();
const customer = useCustomerStore();
const routeLang = useRouteLang();

watch(() => ui.displaySidebar, (value) => {
  if (import.meta.client) {
    if (value) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
});

onServerPrefetch(async () => {
  await loadPublicConfig();
  await loadMe();
  await loadLanguages();
  await loadCurrencies();
  await loadCategories();
  await loadBanners();
});

onMounted(async () => {
  await customer.load();
  await cartLoad();
  await loadParameters();
  await loadCountries();
  await loadEshopAvailabilities();
});

useHead({
  htmlAttrs: {
    lang: routeLang.code.value,
  },
});

</script>
