<template>
    <Notification v-if="isOpen" :payload="payload" :on-close="onClose" />
</template>

<script setup>
import Notification from "@components/ui/toast/notification";

const { $eventBus } = useNuxtApp();

const isOpen = ref(false);
const payload = ref({});

$eventBus.on('notification:open', (value) => {
    payload.value = value;
    isOpen.value = true;
});

const onClose = () => {
    isOpen.value = false;
}

</script>
