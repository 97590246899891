<template>
  <div v-if="finished && auctionBids.length && auction?.status === AUCTION_STATUS.ACTIVE && !auction.winningBid">
    <h2 class="text-base text-primary-dark font-bold pb-3">
      {{ $t('text-pick-auction-winner') }}
    </h2>
    <div class="w-full flex flex-row items-center justify-between space-x-2">
      <Selectbox
        v-model="winningBidId"
        :options="auctionBids"
        name="winningBidId"
        variant="outline"
        dimension="medium"
        class-name="w-full flex item-center appearance-none transition duration-300 ease-in-out text-primary-dark text-xs placeholder:text-primary-dark font-bold overflow-hidden focus:outline-none focus:ring-0 border border-primary-dark rounded-full focus:border-primary-dark-hover"
      />
      <Button
          class="ms-auto w-fit"
          :loading="updating"
          :disabled="updating"
          size="medium"
          :on-click="handleSave"
      >
        {{ $t('text-send') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { AUCTION_STATUS } from "@utils/constants";
import Button from "@components/ui/button";
import Selectbox from "@components/ui/selectbox";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  auction: {
    type: Object,
    required: true
  },
  bids: {
    type: Object,
    required: true
  }
});

const { $eventBus, $dayjs } = useNuxtApp();
const updating = ref(false);
const winningBidId = ref(null);

const now = useNow();
const finishTime = ref($dayjs(props.auction?.finishAt));
const finished = computed(() => finishTime.value.isBefore(now.value));

const auctionBids = computed(() => _.chain(props.bids.items || [])
  .map((item) => ({ id: item.id, name: `${props.variant?.currency?.signPosition === 'before' ? props.variant?.currency?.sign : ''}${item.amount.toFixed(props.variant?.currency?.decimals).replace(/\.00/, '')} ${props.variant?.currency?.signPosition === 'after' ? props.variant?.currency?.sign : ''} - ${item?.supplier?.name || (item?.customer?.firstName + ' ' + item?.customer?.lastName)}` }))
  .value()
);

const handleSave = async () => {
  updating.value = true;
  const result = await auctionStatusUpdate(props.auction.id, {
    status: AUCTION_STATUS.CLOSED,
    winningBidId: +winningBidId.value
  });

  const winningBid = _.chain(props.bids.items || [])
    .filter({ id: +winningBidId.value })
    .first()
    .value();

  if (result && winningBid) {
    props.auction.winningBid = winningBid|| null;
    $eventBus.emit('auction:update', {
      id: props.auction.id,
      winningBid: winningBid || null,
    });
  }
  updating.value = false;
}

</script>
