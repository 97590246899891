import _ from 'lodash-es';

export default function (data, size = 160) {
  if (import.meta.client) {
    var div = document.createElement("div");
    div.innerHTML = data;

    return _.truncate(div.textContent || div.innerText || "", { length: size, separator: /,? +/ });
  }

  return _.truncate(data.replace(/<[^>]*>/g, '').replaceAll('&nbsp;', ' '), { length: size, separator: /,? +/ });
}
