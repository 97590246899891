<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex text-center justify-center font-semibold text-lg md:text-xl text-heading mb-4">
      {{ $t('text-remove-item-from-cart', { name }) }}
    </h2>
    <div class="flex flex-col sm:flex-row items-center justify-center w-full">
      <Button
          class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
          variant="outline"
          size="small"
          :disabled="loading"
          :on-click="handleClose"
      >
        <span class="text-sm px-9">{{ $t('text-do-not-remove') }}</span>
      </Button>
      <Button
          class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
          size="small"
          :disabled="loading"
          :on-click="handleConfirm"
      >
        <span class="text-sm px-9">{{ $t('text-remove-item') }}</span>
      </Button>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected';
import Button from "@components/ui/button";

const cart = useCartStore();
const store = useSelectedStore();
const { $eventBus } = useNuxtApp();
const loading = ref(false);

const name = computed(() => cart.getItem(store.variant?.id)?.variant?.name);

const handleClose = () => {
  $eventBus.emit('cart:item:refresh');
  $eventBus.emit('modal:close');
  $eventBus.emit('sidebar:open', 'CART_VIEW');
  store.setVariant(null);
};

const handleConfirm = async () => {
  loading.value = true;
  await cartRemoveItem(store?.variant?.id);
  handleClose();
};

</script>
