<template>
    <div v-if="data?.supplier" class="w-fit max-w-full flex flex-row space-x-3 items-center overflow-hidden group">
        <Avatar
          :src="data?.supplier?.logo"
          class="cursor-pointer"
          :title="data?.supplier?.name"
          @click="navigate"
        />
        <div class="flex flex-col w-fit max-w-full">
            <div class="flex flex-row overflow-hidden space-x-2">
                <Link
                  :href="translatePath(ROUTES.CODE.SUPPLIER, slugify(data?.supplier?.id, data?.supplier?.name))"
                  class="flex items-center text-sm font-bold truncate mr-4 cursor-pointer group-hover:underline"
                  @click="closeModal"
                >
                  {{ data?.supplier?.name }}
                </Link>
                <SupplierRatingStars v-if="data?.supplier?.ratingScore && show?.rating" :score="data?.supplier?.ratingScore" class="pr-4" />
                <Link
                    v-if="isBuyerBlocked"
                    :href="translatePath(ROUTES.CODE.SETTINGS_NOTIFICATION)"
                    @click="closeModal"
                >
                    <Badge
                      :icon="UserBlock"
                      :text="$t('text-blocked-user')"
                      variant="error"
                    />
                </Link>
                <template v-else-if="show?.status">
                    <Badge
                      v-if="data?.supplier?.status === SUPPLIER_STATUS.WAITING"
                      :icon="ExclamationTriangleSolid"
                      :text="$t(`text-unverified-${type}`)"
                      variant="warning"
                    />
                    <Badge
                      v-else-if="data?.supplier?.status === SUPPLIER_STATUS.ACTIVE"
                      :icon="CheckBadgeSolid"
                      :text="$t(`text-verified-${type}`)"
                      variant="success"
                    />
                    <Badge
                      v-else
                      :icon="ExclamationCircleSolid"
                      :text="$t(`text-unknown-${type}`)"
                      variant="error"
                    />
                </template>
            </div>
            <div class="flex flex-row flex-wrap items-center justify-between text-xs">
                <div class="flex items-center">
                    <div v-if="data?.supplier?.city" :class="data?.supplier?.latitude && data?.supplier?.longitude ? 'hover:underline cursor-pointer' : ''" @click="handleOpenLocation">{{ data?.supplier?.city }}</div>
                    <div v-if="data?.supplier?.distanceKm">, {{ data?.supplier?.distanceKm }} {{ $t('text-unit-km') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { SUPPLIER_STATUS } from "@utils/constants";
import { useCustomerStore } from '@stores/customer';
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import Avatar from "@components/ui/avatar";
import Badge from "@components/ui/badge";
import CheckBadgeSolid from "@components/icons/solid/check-badge-solid";
import ExclamationTriangleSolid from "@components/icons/solid/exclamation-triangle-solid";
import ExclamationCircleSolid from "@components/icons/solid/exclamation-circle-solid";
import UserBlock from "@components/icons/solid/user-block";
import Link from "@components/ui/link/link";
import SupplierRatingStars from "@components/profile/supplier-rating-stars";

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  type: {
    type: String,
    default: ''
  },
  show: {
    type: Object,
    default: () => ({ rating: false, status: false })
  }
});

const { $eventBus } = useNuxtApp();
const customer = useCustomerStore();
const store = useSelectedStore();
const ui = useUIStore();

const isBuyerBlocked = computed(() => {
  return !!_.chain(customer)
    .get('supplier.blocked', [])
    .filter({ id: props?.data?.supplier?.id })
    .value()
    .length;
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(ROUTES.CODE.SUPPLIER, slugify(props.data?.supplier?.id, props.data?.supplier?.name)));
}

const handleOpenLocation = () => {
  if (props.data?.supplier?.latitude && props.data?.supplier?.longitude) {
    if (ui.modalView && ui.displayModal) {
      store.setAction(store.product?.id ? 'RETURN_TO_PRODUCT_MODAL' : 'RETURN_TO_CONTENT_MODAL');
      $eventBus.emit('modal:close');
    }
    store.setMapLocation(props.data?.supplier);
    $eventBus.emit('modal:open', 'LOCATION_MAP_MODAL');
  }
}

</script>
