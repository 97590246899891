import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useSelectedStore } from '@stores/selected'
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const { $eventBus } = useNuxtApp();
  const auth = useAuthStore();
  const cart = useCartStore();
  const store = useSelectedStore();
  const customer = useCustomerStore();
  const config = useRuntimeConfig();

  if (!auth.isLoggedIn || !auth.idCustomer) {
    return;
  }

  customer.loading = true;
  try {
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.CUSTOMER_ID.replace('{id}', auth.idCustomer)}`);

    customer.setData(data);
    customer.loading = false;
    return data;

  } catch (error) {
    Sentry.captureException(error);
    if (import.meta.client && !auth.reloadTimeout) {
      $eventBus.emit('toast:error', 'error.customer-detail.load');
    }
    auth.reset();
    customer.reset();
    cart.reset();
    store.setLoadResetAll();
    customer.loading = false;
  }

  return null;
}
