<template>
  <SidebarWrapper>
    <div v-if="loading" class="grid place-items-center relative">
      <div class="flex items-center justify-center w-96 h-96">
        <Spinner :text="$t('common:text-loading')" />
      </div>
    </div>
    <div v-else class="flex justify-center">
      <section class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-5xl flex flex-col justify-center">
        <h1 class="text-xl md:text-2xl sm:text-3xl 2xl:text-4xl text-heading font-bold mb-4 sm:mb-5 2xl:mb-7">
          {{ localized(data, 'name') }}
        </h1>

        <div class="flex flex-col space-y-4 prose max-w-none" v-html="localized(data, 'content')" />

        <div v-if="showClose" class="flex flex-col sm:flex-row items-center justify-end w-full mt-5">
          <Button
              class="mt-5 sm:mt-0 w-full sm:w-fit"
              :on-click="handleClose"
          >
            {{ $t('text-close') }}
          </Button>
        </div>
      </section>
    </div>
  </SidebarWrapper>
</template>

<script setup>
import _ from 'lodash-es';
import { sitePages } from "@settings/site-pages.settings";
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";
import Spinner from "@components/ui/loaders/spinner/spinner";
import Button from "@components/ui/button";

const props = defineProps({
  subtype: {
    type: String,
    required: true
  },
  showClose: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const data = ref(null);
const loading = ref(true);

const reload = async () => {
  const pages = await loadPostPages(props.subtype);
  data.value = _.get(pages, '0', {});
  loading.value = false;

  const title = plainText(localized(data.value, 'title', localized(data.value, 'name')));
  const description = plainText(localized(data.value, 'description', localized(data.value, 'content')));

  useSeoMeta({
    title: title,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImage: `${sitePages.aquarist.image}`,
    ogType: `${sitePages.aquarist.type}`
  });
}

onMounted(async () => {
  if (loading.value && (import.meta.server || navigator?.onLine)) {
    await reload();
  }
});

onServerPrefetch(async () => {
  await reload();
});

const handleClose = () => {
    $eventBus.emit('sidebar:close');
};

</script>
