import _ from 'lodash-es';

export default function (address) {
  if (!address) return [];
  
  const { t } = useI18n();
  const fullStreet = ((address?.street ? address?.street : '') + ' ' + (address?.streetNo ? address?.streetNo : '') + (address?.houseNo ? `/${address?.houseNo}` : '')).trim();
  const fullName = _.join(_.filter([ 
    address.namePrefix,
    address.firstName,
    address.lastName,
    address.nameSuffix
  ]), ' ').trim();
  return _.filter([
    address?.company !== fullName ? address?.company : null,
    address?.companyPerson ? address?.companyPerson : fullName,
    fullStreet,
    _.join(_.filter([address?.zip, address?.city]), ' '),
    address?.country?.name,
    address?.companyNo ? `${t('text-companyNo')}: ${address?.companyNo}` : null,
    address?.companyVatNo ? `${t('text-companyVatNo')}: ${address?.companyVatNo}` : null,
  ]);
}
