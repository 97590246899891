import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { useAuthStore } from '@stores/auth';

export default function (data) {
    const auth = useAuthStore();

    if (typeof Sentry !== 'undefined' && typeof Sentry.setTag === 'function') {
        Sentry.setTag('SID', auth?.sid || null);
    }
    if (typeof Sentry !== 'undefined' && typeof Sentry.captureEvent === 'function') {
        Sentry.captureEvent(_.pick(data, ['category', 'message', 'level', 'extra']));
    } else if (typeof Sentry !== 'undefined' && typeof Sentry.addBreadcrumb === 'function') {
        Sentry.addBreadcrumb({
          message: data.message,
          level: data.level,
          data: data.extra
        });
    } else {
        console.error('Sentry.captureEvent', data);
    }

    if (data?.extra?.error) {
        checkError(data?.extra?.error, { redirect: data?.redirect || null });
    }
}
