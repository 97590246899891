<template>
    <div class="fixed flex items-start w-full max-w-xs p-4 text-gray-900 bg-accent rounded-lg shadow hover:bg-accent-hover top-20 right-8 z-20" role="alert">
        <div class="shrink-0">
            <img class="w-12 h-12 rounded" :src="payload?.notification?.icon || '/icons/manifest-icon-512.png'" loading="lazy" >
        </div>
        <div class="flex items-center">
            <div class="ms-3 me-3 text-sm font-normal">
                <Link :href="link" :hash="hash" class="flex flex-col" @click="onClose">
                    <div class="mb-1 text-sm font-semibold text-white">{{ payload?.notification?.title }}</div>
                    <img v-if="payload?.notification?.image" class="w-full rounded mb-1" :src="payload?.notification?.image" loading="lazy" >
                    <div class="text-sm font-normal text-white">{{ payload?.notification?.body }}</div>
                    <span class="text-xs italic text-gray-400">{{ $luxon.fromISO(payload?.data?.insertedAt).setLocale(routeLang.code.value).toRelative() }}</span>
                </Link>
            </div>
        </div>
        <div class="flex flex-col justify-start ml-2 mb-3">
            <button
                type="button"
                :class="[ 'flex items-center justify-center rounded-full flex-shrink-0 w-6 h-6 absolute end-2 top-2 transition-colors duration-200 hover:bg-gray-300 hover:bg-opacity-25 focus:outline-none focus:bg-gray-300 focus:bg-opacity-25 text-gray-400' ]"
                :aria-label="$t('text-close')"
                @click="onClose"
            >
                <span class="sr-only">{{ $t('text-close') }}</span>
                <CloseIcon class="w-3 h-3" />
            </button>
        </div>
    </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import Link from "@components/ui/link/link";
import CloseIcon from "@components/icons/close-icon";

const props = defineProps({
    payload: {
        type: Object,
        required: true
    },
    onClose: {
        type: Function,
        default: () => { }
    }
})

const { $luxon } = useNuxtApp();
const routeLang = useRouteLang();

const section = computed(() => ROUTES[routeLang.code.value] || ROUTES[siteSettings.language.code]);

const prefix = computed(() => routeLang.code.value === siteSettings.language.code ? '' : `/${routeLang.code.value}`);


const link = computed(() => {
    const url = props.payload?.data?.link || '';
    if (url) {
        return (url.split('#')[0].match(/^https?:\/\/[^/]+(\/.*)?$/) || ['', '/'])[1] || '/';
    }

    return `${prefix.value}${section.value[props.payload?.data?.routeCode || 'HOME']}` + (props.payload?.data?.routeCode && props.payload?.data?.id ? `/${props.payload?.data?.id}` : '');
});

const hash = computed(() => {
    const url = props.payload?.data?.link || '';
    if (url) {
        const parts = url.split('#');
        if (parts.length > 1) {
            return `#${parts[1]}`;
        }
    }

    return props.payload?.data?.idMessage ? `#m-${props.payload?.data?.idMessage}` : null;
});

</script>
