<template>
    <div class="flex flex-col p-2 flex-1">
      <div :class="[ 'px-1 text-sm text-left prose max-w-none', item.id === selected ? '' : 'text-white' ]">
        {{ variant?.currency?.signPosition === 'before' ? variant?.currency?.sign : '' }}{{ item.amount.toFixed(variant?.currency?.decimals).replace(/\.00/, '') }} {{ variant?.currency?.signPosition === 'after' ? variant?.currency?.sign : '' }}
      </div>
      <div v-if="item.insertedAt" class="flex items-center justify-start text-2xs italic whitespace-nowrap px-1 mt-1 text-right">
        <div :class="item.id === selected ? 'text-gray-500' : 'text-gray-300'">{{ $dayjs(item.insertedAt).fromNow() }}</div>
      </div>
    </div>
</template>

<script setup>
import _ from 'lodash-es';

defineProps({
  variant: {
    type: Object,
    required: true
  },
  item: {
    type: Object,
    required: true
  },
  selected: {
    type: Number,
    default: null
  },
  position: {
    type: String,
    required: true
  }
});

const { $dayjs } = useNuxtApp();

</script>
