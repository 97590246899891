<template>
  <FilterTag
    :link="translatePath(baseRouteCode, slugify(data?.product?.category?.id, data?.product?.category?.name))"
    :on-click="handleClick"
  >
    {{ data?.product?.category?.name }}
  </FilterTag>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE } from "@utils/constants";
import FilterTag from "@components/filter/filter-tag";

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const { $eventBus } = useNuxtApp();
const route = useRoute();

const routeName = computed(() => route?.name || '');

const baseRouteCode = computed(() => ({
    [PRODUCT_TYPE.OFFER]: ROUTES.CODE.OFFERS,
    [PRODUCT_TYPE.WANTED]: ROUTES.CODE.REQUESTS,
    [PRODUCT_TYPE.AUCTION]: ROUTES.CODE.AUCTIONS
})[props.data?.product?.type] || ROUTES.CODE.OFFERS);

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleClick = async () => {
  const params = { ...route.query };
  _.forEach(_.keys(params), key => {
    if (/^p\[\d+\]$/.test(key)) {
      delete params[key];
    }
  });
  closeModal();
  await navigatorTo({
    path: routeName.value.startsWith('classified-ads___') ||
      routeName.value.startsWith('favorites___') ||
      routeName.value.startsWith('offers___') ||
      routeName.value.startsWith('requests___') ||
      routeName.value.startsWith('auctions___') ? route.path : translatePath(ROUTES.CODE.ADS),
    query: params,
  });
  await useSearchParam('idCategory', `${props.data?.product?.category?.id || ''}`);
}

</script>
