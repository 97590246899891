import UAParser from 'ua-parser-js';

export function getDeviceInfo(userAgent: string, deviceLocale: string) {
    const parser = userAgent ? new UAParser(userAgent) : null;
    const deviceInfo = parser ? parser.getResult() : null;
    const browser = deviceInfo ? `${deviceInfo?.browser?.name || ''} ${deviceInfo?.browser?.version || ''}`.trim() : null;
    
    return {
        deviceId: deviceInfo ? (deviceInfo?.ua || '').substring(0, 255) : null,
        deviceType: deviceInfo ? (deviceInfo?.device?.type || 'desktop') : null,
        deviceLocale: deviceLocale.split(';')[0].replace('-', '_'),
        browser: browser ? browser : null,
        os: deviceInfo ? (deviceInfo?.os?.name || null) : null,
        osVersion: deviceInfo ? (deviceInfo?.os?.version || null) : null,
        appVersion: null, //TODO
        codeVersion: null //TODO
    }
}
