<template>
    <div class="flex flex-col h-full">
      <div v-if="showHeader" class="flex items-center justify-between py-4 px-5 md:py-5 md:px-8 mb-4 md:mb-6 border-b border-border-200 border-opacity-75">
        <Logo class="w-24 md:w-auto" @click="closeSidebar" />
        <button
          class="w-7 h-7 flex items-center justify-center rounded-full text-body bg-gray-200 transition-all duration-200 focus:outline-none hover:bg-accent focus:bg-accent hover:text-light focus:text-light"
          :aria-label="$t('text-close')"
          @click="closeSidebar"
        >
          <span class="sr-only">{{ $t("text-close") }}</span>
          <CloseIcon class="w-2.5 h-2.5" />
        </button>
      </div>
      <slot />
    </div>
</template>

<script setup>
import Logo from "@components/ui/logo";
import CloseIcon from "@components/icons/close-icon";

defineProps({
  showHeader: {
    type: Boolean,
    default: true
  }
});

const { $eventBus } = useNuxtApp();

const closeSidebar = () => {
    $eventBus.emit('sidebar:close');
}

</script>
