import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";

export default function (routeCode, suffix = null) {
  const route = useRoute();

  const lang = (route.name || 'cs').slice(-2);
  const section = ROUTES[lang] || ROUTES[siteSettings.language.code];
  const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;

  if (!routeCode || !_.has(section, routeCode)) {
    sentryCaptureEvent({
      message: 'translate-path.empty',
      level: 'error',
      extra: { routeCode, lang, prefix, suffix }
    });
  }

  const path = routeCode && _.has(section, routeCode) ? section[routeCode] : (prefix || '/');
  const slash = suffix && !_.startsWith(`${suffix}`, '/') ? '/' : '';
  const result = `${prefix}${path === '/' && prefix ? '' : path}${slash}${suffix !== null ? suffix : ''}`;

  return result;
}
