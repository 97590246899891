<template>
  <div class="pr-0 md:pr-5">
    <div class="w-full">
      <div class="w-full flex flex-row items-start justify-between space-x-2 mb-4 md:mb-8">
        <h1 class="font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" :title="variant.name" @click="navigate">
            {{ variant.name }}
        </h1>
        <ContentFavorite v-if="variant?.status !== STATUS.CLOSED" :variant="variant" class="flex-shrink-0" />
      </div>

      <Truncate :lines="4" :value="localized(variant, 'content')" class="mb-9" />

      <div v-if="variant?.status !== STATUS.CLOSED && _.get(variant, 'supplier.id') === auth?.idSupplier" class="w-full mb-9">
        <div class="w-full flex flex-col">
          <div class="mb-3 lg:mb-0 w-full">
            <EditContentBtn
              :data="variant"
              variant="big"
              class="!w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-row items-center justify-between mb-9">
      <div class="flex flex-row items-center justify-start space-x-2.5">
        <ContentViews :variant="variant" />
        <ContentLikes :variant="variant" />
        <MessagesBadge :variant="variant" :on-click="() => $emit('select:tab:comments', props.variant.id)" />
      </div>
      <ReportContent :variant="variant" />
    </div>

    <ProfileHeader v-if="variant?.status !== STATUS.CLOSED" :data="variant" class="mb-9" />

    <ContentMessages v-if="variant?.status !== STATUS.CLOSED" :content="variant" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { STATUS } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import ProfileHeader from "@components/profile/profile-header";
import Truncate from "@components/ui/truncate";
import ContentFavorite from "@components/content/content-details/content-favorite";
import EditContentBtn from "@components/content/content-edit/edit-content-btn";
import ContentViews from "@components/content/content-details/content-views";
import ContentLikes from "@components/content/content-details/content-likes";
import MessagesBadge from "@components/common/messages-badge";
import ReportContent from "@components/content/content-details/report-content";
import ContentMessages from "@components/content/content-details/content-messages";

defineEmits(['select:tab:comments']);

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();

const routeCode = computed(() => {
  return {
    inspiration: ROUTES.CODE.INSPIRATION,
    news: ROUTES.CODE.NEWS
  }[props.variant?.type] || ROUTES.CODE.PRODUCT;
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(routeCode.value, slugify(props.variant.id, props.variant.name)));
}

</script>
