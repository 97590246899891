<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-100vw sm:w-70vw h-100vh sm:h-70vh flex flex-col justify-center sm:rounded-panel">
    <div ref="mapRef" class="w-full h-full" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useSelectedStore } from '@stores/selected';

const config = useRuntimeConfig();
const store = useSelectedStore();
const mapRef = ref(null);
const map = ref(null);
const marker = ref(null);

const initials = computed(() => _.chain(store.mapLocation?.name || '')
    .split(' ')
    .map((part) => _.upperFirst(part.charAt(0)))
    .join('')
    .value()
);

const initMap = () => {
  if (!window.google) return;

  const coords = { lat: store.mapLocation?.latitude, lng: store.mapLocation?.longitude };

  map.value = new window.google.maps.Map(mapRef.value, {
      zoom: 10,
      center: coords,
      mapId: config.public.googleMapId
  });

  if (store.mapLocation?.name) {
    // marker.value = new window.google.maps.marker.AdvancedMarkerElement({
    //     map: map.value,
    //     position: coords,
    //     title: store.mapLocation.name
    // });

    marker.value = new window.google.maps.Marker({
        map: map.value,
        position: coords,
        title: store.mapLocation.name
    });

    const infoWindow = new window.google.maps.InfoWindow({
      content: `<div class="w-fit max-w-full flex flex-row space-x-3 items-center overflow-hidden group">
        <div class="w-10 h-10" title="${store.mapLocation?.name}">
          <div class="relative overflow-hidden rounded-full w-10 h-10">
            <img alt="${store.mapLocation?.name}" src="${store.mapLocation?.logo}" class="w-full h-full object-cover object-center ${store.mapLocation?.logo ? '' : 'hidden'}" />
            <div class="flex items-center justify-center text-sm font-bold text-light w-full h-full bg-accent ${store.mapLocation?.logo ? 'hidden' : ''}">
              ${initials.value}
            </div>
          </div>
      </div>
      <div class="flex flex-col w-fit max-w-full font-bold">
        ${store.mapLocation?.name}
      </div>
    </div>`
    });

    infoWindow.open(map.value, marker.value);
  }
}

onMounted(async () => {
  if (import.meta.client && window && window.google) {
    initMap();
  }
});

</script>
