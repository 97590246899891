import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useEnumStore } from '@stores/enum';

export default async function (force = true) {
  const store = useEnumStore();
  const routeLang = useRouteLang();

  if (store?.availabilities?.length && !force) return;

  store.setLoading(true);
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_AVAILABILITY, {
    params: {
      locale: routeLang.locale.value
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-eshop-availabilities',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    store.setAvailabilities(data.value);
  }
  store.setLoading(false);
}
