<template>
    <ClientOnly>
        <VueCountdown v-if="status === STATUS_ACTIVE && running" v-slot="{ days, hours, minutes, seconds }" :time="diff" class="w-full" @end="onCountdownEnd">
            <div class="w-full flex flex-col items-start justify-start">
                <div v-if="running" :class="[ 'w-full', isDetail ? 'text-lg font-bold' : 'text-sm', days+hours === 0 ? 'text-red-600' : '' ]">
                    <span v-if="now.isBefore(startTime)">
                        {{ $t('text-auction-starting-in') }}:
                    </span>
                    <span v-else-if="now.isBefore(finishTime)">
                        {{ $t('text-auction-finishing-in') }}:
                    </span>
                </div>
                <div v-if="running" :class="[ 'w-full flex items-center justify-evenly font-bold', days+hours === 0 ? 'text-red-600' : (now.isBefore(startTime) ? 'text-primary-dark' : (now.isBefore(finishTime) ? 'text-primary-light' : '')), className ]">
                    <div v-if="days">{{ days }}<sup>{{ $t('text-countdown-days') }}</sup></div>
                    <div v-if="days || hours">{{ hours }}<sup>{{ $t('text-countdown-hours') }}</sup></div>
                    <div v-if="days || hours || minutes">{{ minutes }}<sup>{{ $t('text-countdown-minutes') }}</sup></div>
                    <div>{{ seconds }}<sup>{{ $t('text-countdown-seconds') }}</sup></div>
                </div>
                <div v-else class="text-primary-light mb-5 text-lg font-bold">
                    {{ $t('text-auction-finished') }}
                </div>
            </div>
        </VueCountdown>
        <div v-else class="text-primary-light mb-5 text-lg font-bold">
            {{ $t('text-auction-finished') }}
        </div>
    </ClientOnly>
</template>

<script setup>
import { STATUS_ACTIVE } from "@utils/constants";

const props = defineProps({
    status: {
        type: String,
        default: ''
    },
    startAt: {
        type: String,
        default: ''
    },
    finishAt: {
        type: String,
        default: ''
    },
    isDetail: {
        type: Boolean,
        default: false
    },
    className: {
        type: String,
        default: ''
    }
})

const { $dayjs } = useNuxtApp();
const running = ref(true);

const now = useNow();
const startTime = ref($dayjs(props.startAt));
const finishTime = ref($dayjs(props.finishAt));
const time = ref(null);

if (now.value.isBefore(startTime.value)) {
    time.value = startTime.value;
} else if (now.value.isBefore(finishTime.value)) {
    time.value = finishTime.value;
} else {
    running.value = false;
}

const diff = ref(time.value ? time.value.diff(now.value, 'millisecond') : null);

const onCountdownEnd = () => {
    if (now.value.isBefore(finishTime.value)) {
        time.value = finishTime.value;
        diff.value = time.value.diff(now.value, 'millisecond');
    } else {
        running.value = false;
    }
}

</script>
