<template>
    <form @submit.prevent="">
      <PasswordInput
        v-model="password"
        :label="$t('text-new-password')"
        name="password"
        :error="errors?.password?.message ? $t(errors.password.message) : ''"
        variant="outline"
        class-name="mb-5"
        :required="true"
      />
      <PasswordInput
        v-model="passwordRepeat"
        :label="$t('text-confirm-password')"
        name="password-repeat"
        :error="errors?.passwordRepeat?.message ? $t(errors.passwordRepeat.message) : ''"
        variant="outline"
        class-name="mb-5"
        :required="true"
      />
      <Button 
        class-name="w-full h-11" 
        :loading="loading"
        :disabled="loading"
        :on-click="handleSubmit"
      >
        {{ $t('text-reset-password') }}
      </Button>
      <Alert
        v-if="errorMsg"
        variant="error"
        :message="errorMsg"
        class="mt-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      />
    </form>
</template>

<script setup>
import PasswordInput from "@components/ui/password-input";
import Button from "@components/ui/button";
import Alert from "@components/ui/alert";

const props = defineProps({
    onSubmit: {
        type: Function,
        default: () => { }
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const password = ref('');
const passwordRepeat = ref('');
const errors = ref({});
const errorMsg = ref('');

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (!password.value) {
    errors.value.password = { message: 'error-password-required' };
  } 
  if (!passwordRepeat.value) {
    errors.value.passwordRepeat = { message: 'error-password-repeat-required' };
  } 
  if (passwordRepeat.value !== password.value) {
    errors.value.passwordRepeat = { message: 'error-match-passwords' };
  } 
  if (Object.keys(errors.value).length !== 0) {
    errorMsg.value = 'error-some-fields-required';
    return false;
  }

  return true;
}

const handleSubmit = () => {
  if (!isValid()) {
    return;
  }
  props.onSubmit({ newPassword: password.value, newPasswordRepeat: passwordRepeat.value });
};

const showPassword = useState('password-input', () => false);
showPassword.value = false;

</script>
