export default function () {
  const windowSize = useWindowSize();

  const width = computed(() => {
      if (import.meta.client) {
          if (windowSize.width.value >= 1536) return 4;
          else if (windowSize.width.value >= 1024) return 3;
          else if (windowSize.width.value >= 768) return 2;
          else return 1;
      }
      return 4;
  });

  return { width };
}
