import _ from 'lodash-es';
import { useCartStore } from '@stores/cart';

export default async function (data) {
  const cart = useCartStore();

  const index = _.findIndex(cart.list.items, { id: data.id });
  let group = index !== -1 ? _.pullAt(cart.list.items, index) : null;
  if (group) {
    group = _.assignIn(group, _.omit(data, ['variants']));
    _.forEach(data.variants, (item) => {
      let found = false;
      _.forEach(group.variants, (groupItem) => {
        if (groupItem.variant.id === item.variant.id) {
          _.assign(groupItem, item);
          found = true;
        }
      });
      if (!_.has(group, 'variants')) {
        group.variants = [];
      }
      if (!found) {
        group.variants.unshift(item);
      }
    });
  } else {
    group = data;
  }
  cart.list.items.unshift(group);
}
