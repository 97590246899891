import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    if (typeof Sentry !== 'undefined' && typeof Sentry.addBreadcrumb === 'function') {
        Sentry.addBreadcrumb({
          message: info,
          level: 'debug',
          data: error?.data || {}
        });
    }
    if (typeof Sentry !== 'undefined' && typeof Sentry.captureException === 'function') {
        Sentry.captureException(error);
    }
  }

  nuxtApp.hook('vue:error', (error, instance, info) => {
    if (typeof Sentry !== 'undefined' && typeof Sentry.addBreadcrumb === 'function') {
        Sentry.addBreadcrumb({
          message: info,
          level: 'debug',
          data: error?.data || {}
        });
    }
    if (typeof Sentry !== 'undefined' && typeof Sentry.captureException === 'function') {
        Sentry.captureException(error);
    }
  })
})
