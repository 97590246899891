import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (idSupplier, pagination) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.SUPPLIER_ID_RATING, {
    method: 'GET',
    path: { idSupplier },
    params: {
      page: pagination?.page || 1,
      limit: pagination?.itemsPerPage || 5
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'supplier-rating-load',
      level: 'error',
      extra: { error: error.value }
    });

    return null;
  }

  return data.value;
}
