import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const config = useRuntimeConfig();
  const store = useSelectedStore();
  const customer = useCustomerStore();
  const route = useRoute();
  const switchLocalePath = useSwitchLocalePath();

  if (route.path !== switchLocalePath(customer?.language?.code || config.public.defaultLanguage)) {
    await navigatorTo(switchLocalePath(customer?.language?.code || config.public.defaultLanguage));
    store.setLoadResetAll();
    await loadCategories(true);
    await loadParameters(true);
    await loadEshopAvailabilities(true);
    // await loadAbandonmentReasons(true);
    await loadBanners(true);
  }
}
