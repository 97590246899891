<template>
    <div :class="[ 'relative', sizeClasses[size] ]">
      <StarIcon class="absolute inset-0 w-full h-full text-gray-300 hover:text-gray-400" />
      <div :class="[ 'absolute inset-0 overflow-hidden', decimalClass ]">
        <StarSolid class="h-full text-orange hover:text-orange-hover" />
      </div>
    </div>
</template>

<script setup>
import StarIcon from "@components/icons/outline/star-icon";
import StarSolid from "@components/icons/solid/star-solid";

const props = defineProps({
    value: {
        type: Number,
        required: true
    },
    size: {
        type: String,
        default: 'medium' // small, medium, big
    }
});

const sizeClasses = {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    big: 'w-8 h-8',
}

const decimalClass = computed(() => {
    const decimals = props.value === 1 ? 12 : Math.round(props.value % 1 * 12);

    return [ 'w-0', 'w-1/12', 'w-2/12', 'w-3/12', 'w-4/12', 
        'w-5/12', 'w-6/12', 'w-7/12', 'w-8/12', 
        'w-9/12', 'w-10/12', 'w-11/12', 'w-full' 
    ][decimals];
});

</script>
