import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { CART_KEY } from "@utils/constants";

export const useCartStore = defineStore(CART_KEY, () => {
  const loading = useState('cart.loading', () => false);
  const setLoading = (value) => {
    loading.value = value;
  }

  const list = useState('cart.list', () => ({ items: [] }));
  const pagination = useState(`cart.list.pagination`, () => ({ page: 1, pageCount: 1, itemsPerPage: 100, itemCount: 0 }));

  const isEmpty = ref(true);
  const totalItems = ref(0);
  const totalUniqueItems = ref(0);
  const total = ref(0);
  const totalNet = ref(0);
  const totalTax = ref(0);
  const discount = ref(0);
  const hasCoupon = ref(false);
  const meta = ref(null);

  const generateCartItem = (item) => {
    return _.has(item, 'variant') ? item : {
      price: item?.price,
      quantity: item?.quantity || 1,
      sum: item?.price * (item?.quantity || 1),
      variant: item
    };
  }

  const calculateTotals = (listValue = list.value) => {
    _.forEach(listValue.items, item => {
      _.forEach(item.variants, variant => {
        _.assign(variant, {
          itemTotal: variant.price * (variant.quantity || 0) * (item?.currency?.currencyRate || 1),
          itemTotalNet: variant.priceNet * (variant.quantity || 0) * (item?.currency?.currencyRate || 1),
          itemTotalTax: (variant.price - variant.priceNet) * (variant.quantity || 0) * (item?.currency?.currencyRate || 1)
        });
      });
    });

    const items = _.flatMap(listValue.items, (item) => item.variants || []);
    totalItems.value = _.sumBy(items, 'quantity');
    totalUniqueItems.value = items.length;
    total.value = _.sumBy(items, 'itemTotal');
    totalNet.value = _.sumBy(items, 'itemTotalNet');
    totalTax.value = _.sumBy(items, 'itemTotalTax');
    isEmpty.value = totalUniqueItems.value === 0;
  }

  watch(() => list.value, (value) => calculateTotals(value), {deep: true, immediate: true});

  const getCart = (id) => {
    return _.chain(list.value.items)
      .find((item) => item?.id === id)
      .value();
  }

  const getItem = (id) => {
    return _.chain(list.value.items)
      .flatMap((item) => item.variants || [])
      .find((item) => item?.variant?.id === id)
      .value();
  }

  const updateItem = (id, item) => {
    const cartItem = getItem(id);
    if (cartItem) {
      _.assign(cartItem, item);
    }
  }

  const isInCart = (id) => {
    return !!getItem(id);
  }

  const isInStock = (id) => {
    const item = getItem(id);
    return item ? _.get(item, 'variant.stockCount', 0) !== 0 && _.get(item, 'quantity') <= _.get(item, 'variant.stockCount', 0) && _.get(item, 'availability.isProductOrderable', true) : false;
  }

  const setHasCoupon = (value) => {
    hasCoupon.value = value;
  }

  const reset = () => {
    list.value.items = [];
    pagination.value.page = 1;
  }

  return {
    loading, setLoading,
    list,
    pagination,
    reset,
    isEmpty,
    totalItems,
    totalUniqueItems,
    total,
    totalNet,
    totalTax,
    discount,
    meta,
    hasCoupon,
    setHasCoupon,
    generateCartItem,
    getCart,
    getItem,
    updateItem,
    isInCart,
    isInStock
  }
})
