import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const customer = useCustomerStore();

  customer.loading = true;

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.VISITOR, {
    method: 'GET'
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-visitor',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    customer.setLanguage(data.value?.language || {});
    customer.setCurrency(data.value?.currency || {});
  }
  customer.loading = false;
}
