<template>
  <div class="pr-0 md:pr-5">
    <div ref="observedElement" class="w-full">
      <div class="w-full flex flex-row items-center justify-between space-x-2 mb-4 md:mb-8">
        <div class="w-full flex flex-wrap">
          <FilterTagCategory :data="variant" class="my-0.5" />
          <FilterTagParameters :data="variant" class-name="my-0.5" />
        </div>
        <VariantFavorite v-if="variant?.product?.status !== STATUS.CLOSED" :variant="variant" class="flex-shrink-0" />
      </div>

      <h1 class="font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" :title="variant.name" @click="navigate">
        {{ variant.name }}
      </h1>

      <div v-if="variant?.product?.status !== STATUS.CLOSED" class="w-full my-2">
        <div class="flex flex-row items-center justify-between">
          <span v-if="(auctionPrice || _.get(variant, 'price', null)) === null" class="flex items-center">
            <ins class="text-base font-bold text-primary-dark no-underline">
              {{ $t('text-price-negotiable') }}
            </ins>
          </span>
          <span v-else class="flex items-center">
            <ins class="text-base font-bold text-primary-dark no-underline">
              {{ basePrice ? basePrice : price }}
            </ins>
            <del v-if="discount" class="text-sm md:text-base font-normal text-muted ms-2">
              {{ price }}
            </del>
          </span>
          <VariantShare :variant="variant" />
        </div>
      </div>

      <Truncate :lines="4" :value="localized(variant, 'content')" format="html" />

      <div v-if="store.action !== 'HIDE_ADD_TO_CART' && isOrderable && variant?.product?.type !== PRODUCT_TYPE.WANTED" class="w-full">
        <div class="mt-4 flex flex-col">
          <span v-if="variant.stockCount" class="text-base text-body whitespace-nowrap">
            {{ isOrderable
              ? (variant.stockCount ? formatString(variant.stockCount, $t(variant?.product?.type === PRODUCT_TYPE.WANTED ? 'text-pieces-required' : 'text-pieces-available')) : '')
              : $t('text-out-stock')
            }}
          </span>
        </div>
      </div>

      <div :class="variant?.product?.status === STATUS.CLOSED ? 'mt-5' : ''">
        <CountDown v-if="variant?.product?.type === PRODUCT_TYPE.AUCTION" class-name="pb-2 text-xl" :status="variant?.auction?.status" :start-at="variant?.auction?.startAt" :finish-at="variant?.auction?.finishAt" :is-detail="true" />
      </div>

      <div :class="variant?.product?.type === PRODUCT_TYPE.AUCTION ? 'w-full' : 'w-full my-8'">
        <div class="w-full flex flex-col">
          <div v-if="store.action !== 'HIDE_ADD_TO_CART'" class="mb-3 lg:mb-0 w-full">
            <AddToCartBtn
              v-if="variant?.product?.status === STATUS.CLOSED"
              :data="variant"
              variant="disabled"
              :disabled="true"
              class="!w-full mb-5"
            >
              {{ $t('text-product-status-closed') }}
            </AddToCartBtn>
            <AuctionPlaceBid
              v-else-if="variant?.product?.type === PRODUCT_TYPE.AUCTION"
              :variant="variant"
              :auction="variant.auction"
              class="!w-full"
            />
            <EditProductBtn
              v-else-if="_.get(variant, 'product.supplier.id') === auth?.idSupplier"
              :data="variant.product"
              variant="big"
              class="!w-full"
            />
            <AddToCart
              v-else
              :data="variant"
              variant="simple"
              :disabled="!isOrderable"
              class="!w-full"
            />
          </div>
        </div>
      </div>
    </div>

    <AuctionBids v-if="variant?.product?.type === PRODUCT_TYPE.AUCTION && variant?.auction?.id" :variant="variant" class="mb-8" />

    <div class="w-full flex flex-row items-center justify-between mb-9">
      <div class="flex flex-row items-center justify-starts space-x-2.5">
        <VariantViews :variant="variant" />
        <VariantLikes :variant="variant" />
        <MessagesBadge :variant="variant" :on-click="() => $emit('select:tab:comments', props.variant.id)" />
      </div>
      <ReportVariant :variant="variant" />
    </div>

    <VariantParameters :variant="variant" class="mb-9" />

    <VerifiedUser v-if="variant?.product?.status !== STATUS.CLOSED" :data="variant?.product" :type="CART_TYPE.SELLER" class="mb-9" />

    <div v-if="variant?.product?.status !== STATUS.CLOSED" class="w-full flex flex-row items-center justify-between">
      <h2 class="text-base text-primary-dark font-bold pb-3">
        {{ $t(variant?.product?.type === PRODUCT_TYPE.WANTED ? 'text-requester' : 'text-supplier') }}
      </h2>
      <Link
        :href="translatePath(ROUTES.CODE.SUPPLIER, slugify(variant?.product?.supplier?.id, variant?.product?.supplier?.name))"
        class="whitespace-nowrap text-xs underline hover:no-underline pb-3"
        @click="closeModal"
      >
        {{ $t('text-show-profile') }}
      </Link>
    </div>
    <ProfileHeader v-if="variant?.product?.status !== STATUS.CLOSED" :data="variant?.product" :type="CART_TYPE.SELLER" :show="{ rating: true }" class="mb-9" />

    <VariantMessages v-if="variant?.product?.status !== STATUS.CLOSED" :variant="variant" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE, CART_TYPE, STATUS } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import { formatString } from "@utils/format-string";
import usePrice from "@utils/use-price";
import AddToCart from "@components/product/add-to-cart/add-to-cart";
import AddToCartBtn from "@components/product/add-to-cart/add-to-cart-btn";
import EditProductBtn from "@components/product/product-edit/edit-product-btn";
import ProfileHeader from "@components/profile/profile-header";
import VariantFavorite from "@components/product/product-details/variant-favorite";
import VariantViews from "@components/product/product-details/variant-views";
import VariantLikes from "@components/product/product-details/variant-likes";
import MessagesBadge from "@components/common/messages-badge";
import FilterTagCategory from "@components/filter/filter-tag-category";
import FilterTagParameters from "@components/filter/filter-tag-parameters";
import Truncate from "@components/ui/truncate";
import VariantParameters from "@components/product/product-details/variant-parameters";
import VerifiedUser from "@components/profile/verified-user";
import Link from "@components/ui/link/link";
import VariantMessages from "@components/product/product-details/variant-messages";
import ReportVariant from "@components/product/product-details/report-variant";
import VariantShare from "@components/product/product-details/variant-share";
import CountDown from "@components/ui/countdown";
import AuctionPlaceBid from "@components/auction/auction-place-bid";
import AuctionBids from "@components/auction/auction-bids";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const observedElement = ref(null);

defineEmits(['select:tab:comments']);

const auctionPrice = computed(() => _.get(props.variant, 'auction.currentBid', null) || _.get(props.variant, 'auction.startingBid', null));

const price = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.price);

const basePrice = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.basePrice);

const discount = computed(() => usePrice({
  amount: auctionPrice.value || +props.variant?.price,
  baseAmount: auctionPrice.value || +props.variant?.sale_price,
  currencyCode: props.variant?.currency?.code
})?.discount);

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(ROUTES.CODE.PRODUCT, slugify(props.variant.id, props.variant.name)));
}

const isOrderable = computed(() => {
  return _.get(props.variant, 'stockCount', 0) !== 0 && _.get(props.variant, 'availability.isProductOrderable', true);
});

const auctionUpdate = (value) => {
  if (props.variant?.auction?.id === value.id) {
    _.assignIn(props.variant.auction, value);
  }
};

onMounted(async () => {
  $eventBus.on('auction:update', auctionUpdate);
});

onUnmounted(() => {
  $eventBus.off('auction:update', auctionUpdate);
});

</script>
