import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CONTENT_TYPE } from "@utils/constants";
import { useCategoryStore } from '@stores/category';

export default async function (force = false) {
  const store = useCategoryStore();
  const routeLang = useRouteLang();

  if (!store?.data?.length || force) {
    store.setLoading(true);
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CATEGORY, {
      params: {
        locale: routeLang.locale.value
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'load-categories.eshop',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      store.setEshop(data.value);
    }
    store.setLoading(false);
  }

  if (!store?.content?.length || force) {
    store.setLoading(true);
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTENT_CATEGORY, {
      params: {
        type: CONTENT_TYPE.INSPIRATION,
        locale: routeLang.locale.value
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'load-categories.inspiration',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      store.setContent(data.value);
    }
    store.setLoading(false);
  }

  if (!store?.news?.length || force) {
    store.setLoading(true);
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONTENT_CATEGORY, {
      params: {
        type: CONTENT_TYPE.NEWS,
        locale: routeLang.locale.value
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'load-categories.news',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      store.setNews(data.value);
    }
    store.setLoading(false);
  }

  store.setLoaded(true);
}
