<template>
    <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
      <div class="flex justify-center">
        <Logo />
      </div>
      <p v-if="!linkSent && !updated" class="text-center text-sm md:text-base leading-relaxed text-body mt-4 sm:mt-5 mb-7 sm:mb-10">
        {{ !hash ? $t('forgot-password-email-helper') : $t('forgot-password-token-helper') }}
      </p>
      <EnterEmailView
        v-if="!hash && !linkSent"
        :loading="isLoading"
        :on-submit="handleEmailSubmit"
      />
      <EnterNewPasswordView
        v-if="hash && !updated"
        :loading="resetting"
        :on-submit="handleResetPassword"
      />
      <Alert
        v-if="errorMsg"
        :message="errorMsg"
        :variant="errorType"
        class="mt-6"
        :closeable="true"
        :on-close="() => errorMsg = ''"
      >
        <button
          v-if="errorMsg === 'forgot-password.error.already-set'"
          class="underline text-sm font-semibold transition-colors duration-200 focus:outline-none hover:no-underline focus:no-underline"
          @click="() => openModal('FORGOT_VIEW')"
        >
          {{ $t('forgot-password.error.already-set.link.label') }}
        </button>
      </Alert>

      <template v-if="!updated">
        <div class="flex flex-col items-center justify-center relative text-sm text-heading mt-9 sm:mt-11 mb-7 sm:mb-8">
          <hr class="w-full" >
          <span class="absolute start-2/4 -top-2.5 px-2 -ms-4 bg-light">
            {{ $t('text-or') }}
          </span>
        </div>
        <div class="text-sm sm:text-base text-body text-center">
          {{ $t('text-back-to') }}
          <button
            class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline focus:no-underline"
            @click="() => openModal('LOGIN_VIEW')"
          >
            {{ $t('text-login') }}
          </button>
        </div>
      </template>
      <div v-else class="mt-8">
        <Button
          class="w-full h-11 sm:h-12"
          :on-click="() => openModal('LOGIN_VIEW')"
        >
          {{ $t('text-login') }}
        </Button>
      </div>
    </div>
</template>

<script setup>
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useSelectedStore } from '@stores/selected';
import Logo from "@components/ui/logo";
import Alert from "@components/ui/alert";
import EnterEmailView from "./enter-email-view";
import EnterNewPasswordView from "./enter-new-password-view";
import Button from "@components/ui/button";

const props = defineProps({
  hash: {
    type: String,
    default: ''
  }
});

const store = useSelectedStore();
const errorMsg = ref('');
const errorType = ref('error');

const isLoading = ref(false);
const linkSent = ref(false);
const resetting = ref(false);
const updated = ref(false);

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();

const forgotPassword = async (body, callbacks) => {
    isLoading.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_FORGOT_PASSWORD, {
      method: 'POST',
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      callbacks.onSuccess(data.value);
    }
    isLoading.value = false;
};

const handleEmailSubmit = async ({ email }) => {
  errorMsg.value = '';
  await forgotPassword(
    {
      email,
    },
    {
      onSuccess: () => {
        errorMsg.value = 'text-check-email-for-token';
        errorType.value = 'success';
        linkSent.value = true;
      },
      onError: (error) => {
        errorMsg.value = {
          403: 'forgot-password.error.unknown',
          404: 'forgot-password.error.not-found'
        }[error?.data?.statusCode] || 'forgot-password.error.unknown';
        if (error?.data?.statusCode !== 404) {
          sentryCaptureEvent({
            message: 'forgot-password.handleEmailSubmit',
            level: 'error',
            extra: { error }
          });
        }
      }
    }
  );
}

const resetPassword = async (body, callbacks) => {
    resetting.value = true;
    const { data, error, status } = await useApiFetch(API_ENDPOINTS.CUSTOMER_FORGOT_PASSWORD_HASH, {
      method: 'PUT',
      path: {
        hash: props.hash
      },
      body
    });
    if (status.value === 'error') {
      callbacks.onError(error.value);
    } else {
      await callbacks.onSuccess(data.value);
    }
    resetting.value = false;
};

const handleResetPassword = async ({ newPassword, newPasswordRepeat }) => {
  errorMsg.value = '';
  await resetPassword(
    {
      newPassword,
      newPasswordRepeat
    },
    {
      onSuccess: async () => {
        $toast.success(t('password-successful'));
        errorMsg.value = 'password-successful-please-login';
        errorType.value = 'success';
        updated.value = true;
        store.setAction('REDIRECT_TO_HOME');
      },
      onError: (error) => {
        errorMsg.value = {
          400: 'forgot-password.error.already-set',
          403: 'forgot-password.error.forbidden',
          404: 'forgot-password.error.not-found'
        }[error?.data?.statusCode] || 'forgot-password.error.unknown';
        if (![400, 404].includes(error?.data?.statusCode || 0)) {
          sentryCaptureEvent({
            message: 'forgot-password.handleResetPassword',
            level: 'error',
            extra: { error }
          });
        }
      }
    }
  );
}

const openModal = (view) => {
  errorMsg.value = '';
  errorType.value = 'error';
  $eventBus.emit('modal:close');
  $eventBus.emit('modal:open', view);
}

</script>
