<template>
    <NuxtLink :to="{ path: href || route.path, query, hash, external }" :active-class="activeClass">
      <slot />
    </NuxtLink>
</template>

<script setup>

defineProps({
    href: {
        type: String,
        default: ''
    },
    query: {
        type: Object,
        default: () => {}
    },
    hash: {
        type: String,
        default: ''
    },
    activeClass: {
        type: String,
        default: ''
    },
    external: {
        type: Boolean,
        default: false
    }
});

const route = useRoute();

</script>
