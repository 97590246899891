import _forEach from 'lodash-es/forEach';
import _filter from 'lodash-es/filter';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useCartStore } from '@stores/cart';

export default async function (id) {
  const cart = useCartStore();
  const item = cart.getItem(id);

  if (item) {
    const { error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART, {
      method: 'DELETE',
      params: {
        idProductVariant: id
      }
    });

    if (status.value === 'error') {
      sentryCaptureEvent({
        message: 'cart-remove-item',
        level: 'error',
        extra: { error: error.value }
      });
    } else {
      _forEach(cart.list.items, (cart) => cart.variants = _filter(cart.variants, (item) => item?.variant?.id !== id));
    }
  }

}
