import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (body) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.SUPPLIER, {
    method: 'POST',
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'create-supplier',
      level: 'error',
      extra: { error: error.value }
    });

    return false;
  }

  return data.value;
}
