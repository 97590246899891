<template>
  <div class="py-6 px-5 sm:p-8 bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center">
    <h2 class="flex font-semibold text-lg md:text-xl text-heading mb-2">
      {{ type === CART_TYPE.SELLER ? $t('text-order-seller-rating') : $t('text-order-buyer-rating') }} #{{ store.order?.number }}
    </h2>
    <hr class="mb-2 md:mb-4" >
    <div class="w-full flex justify-center mb-5 whitespace-nowrap">
        <component
            :is="icon(1)"
            class="w-6 h-6 text-orange hover:text-orange-hover cursor-pointer"
            @click="() => handleClick(1)"
        />
        <component
            :is="icon(2)"
            class="w-6 h-6 text-orange hover:text-orange-hover cursor-pointer"
            @click="() => handleClick(2)"
        />
        <component
            :is="icon(3)"
            class="w-6 h-6 text-orange hover:text-orange-hover cursor-pointer"
            @click="() => handleClick(3)"
        />
        <component
            :is="icon(4)"
            class="w-6 h-6 text-orange hover:text-orange-hover cursor-pointer"
            @click="() => handleClick(4)"
        />
        <component
            :is="icon(5)"
            class="w-6 h-6 text-orange hover:text-orange-hover cursor-pointer"
            @click="() => handleClick(5)"
        />
    </div>
    <div class="flex flex-col sm:flex-row justify-between w-full">
      <TextArea
        v-model="review"
        :label="$t('text-order-review')"
        name="review"
        type="text"
        variant="outline"
        class-name="w-full mb-5"
        :error="errors?.review?.message ? $t(errors.review.message) : ''"
      />
    </div>
    <div class="flex flex-col sm:flex-row justify-end w-full">
      <Button
          class="ms-auto"
          :disabled="loading"
          :loading="loading"
          :on-click="handleSubmit"
      >
        {{ $t('text-submit') }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { CART_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useSelectedStore } from '@stores/selected';
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";
import StarIcon from "@components/icons/outline/star-icon";
import StarSolid from "@components/icons/solid/star-solid";

const auth = useAuthStore();
const store = useSelectedStore();
const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const errors = ref({});
const loading = ref(false);
const score = ref(store.rating.score);
const review = ref(store.rating.review);

const type = store.order.supplier.id === auth.idSupplier ? CART_TYPE.SELLER : CART_TYPE.BUYER;

const icon = (value) => {
    return value <= score.value ? StarSolid : StarIcon;
};

const handleClick = (value) => {
    score.value = value;
}

const handleSubmit = async () => {
  loading.value = true;
  const result = await orderRating(store.order?.id, {
    score: score.value,
    review: review.value
  }, true);
  if (result) {
    $toast.success(t('success.order.rating.update'));
    const buyerRating = _.find(store.order?.ratings || [], { type: CART_TYPE.BUYER });
    const sellerRating = _.find(store.order?.ratings || [], { type: CART_TYPE.SELLER });
    $eventBus.emit('order:update', {
      id: store.order.id,
      ratings: _.filter(_.concat(
        type === CART_TYPE.BUYER ? [ _.isEmpty(result) ? { type, score: score.value, review: review.value || '' } : result ] : buyerRating || [],
        type === CART_TYPE.SELLER ? [ _.isEmpty(result) ? { type, score: score.value, review: review.value || '' } : result ] : sellerRating || [],
      ))
    });
    $eventBus.emit('modal:close');
  } else {
    $toast.error(t('error.order.rating.update'));
  }
  loading.value = false;
}

</script>
