import { API_ENDPOINTS } from "@utils/api/endpoints";

export default async function (idCart, body) {
  const { data, error, status } = await useApiFetch(API_ENDPOINTS.ESHOP_CHECKOUT_CART_ORDER, {
    method: 'PUT',
    path: { idCart },
    body
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'cart-cancel-order',
      level: 'error',
      extra: { error: error.value }
    });

    return false;
  }

  return data.value;
}
