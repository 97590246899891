export const CUSTOMER_KEY = "customer";
export const SELECTED_KEY = "selected";
export const CART_KEY = "cart";
export const UI_KEY = "ui";
export const AUTH_KEY = "auth";
export const CATEGORY_KEY = "category";
export const ENUM_KEY = "enums";
export const CONFIG_KEY = "config";

export const TOKEN = "token";
export const LIMIT = 10;
export const SUPER_ADMIN = "super_admin";
export const CUSTOMER = "customer";

export const PRODUCT_SUBTYPE = {
    main: 'main',
    imain: 'imain',
    nmain: 'nmain',
    rmain: 'rmain',
    ads: 'ads',
    category: 'category',
    supplier: 'supplier',
    isupplier: 'isupplier',
    offer: 'offer',
    wanted: 'wanted',
    auction: 'auction',
    favorite: 'favorite',
    ifavorite: 'ifavorite',
    nfavorite: 'nfavorite',
    inspiration: 'inspiration',
    news: 'news',
};

export const CATEGORY_TYPE = {
    ESHOP: 'eshop',
    CONTENT: 'content',
    NEWS: 'news',
};

export const CONTENT_TYPE = {
    PAGE: 'page',
    INSPIRATION: 'inspiration',
    NEWS: 'news',
    REFERENCE: 'reference',
};

export const CART_TYPE = {
    BUYER: 'buyer',
    SELLER: 'seller',
};

export const SUPPLIER_STATUS = {
    WAITING: 'waiting',
    ACTIVE: 'active',
    BLOCKED: 'blocked',
    DELETED: 'deleted',
};

export const CART_STATUS = {
    ACTIVE: 'active',
    ORDERED: 'ordered',
    CLOSED: 'closed',
};

export const PRODUCT_TYPE = {
    OFFER: 'offer',
    WANTED: 'wanted',
    AUCTION: 'auction',
};

export const PRODUCT_CONTENT_TYPE = {
    CONTENT: 'content',
    DISCOUNT: 'discount',
    PACKAGE: 'package',
};

export const STATUS = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    CLOSED: 'closed',
    HIDDEN: 'hidden',
};

export const STATUS_LIST_NEW = [
    {
        id: 'draft',
        name: 'text-status-draft'
    },
    {
        id: 'active',
        name: 'text-status-active'
    },
    {
        id: 'hidden',
        name: 'text-status-hidden'
    },
];

export const STATUS_LIST = [
    {
        id: 'active',
        name: 'text-status-active'
    },
    {
        id: 'hidden',
        name: 'text-status-hidden'
    },
];

export const STATUS_ACTIVE = 'active';
export const STATUS_HIDDEN = 'hidden';

export const CHAT_MESSAGE_TYPE = {
    VARIANT: 'variant',
    CONTENT: 'content',
    CART: 'cart'
}

export const ORDER_STATUS = {
    ACCEPTED: 'accepted',
    REFUSED: 'refused',
    WAITING: 'waiting',
    PAID: 'paid',
    SENT: 'sent',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
};

export const PICK_WINNER_TYPES = {
    AUTO: 'auto',
    MANUAL: 'manual',
};

export const AUCTION_STATUS = {
    ACTIVE: 'active',
    HIDDEN: 'hidden',
    CLOSED: 'closed',
    CANCELED: 'canceled',
    DELETED: 'deleted',
};
