<template>
  <div v-if="finished && auction.winningBid">
    <h2 class="text-base text-primary-dark font-bold pb-3">
      {{ $t('text-auction-winner') }}
    </h2>
    <ProfileHeader :data="auction.winningBid" :type="CART_TYPE.BUYER" :show="{ status: true }" />
  </div>
</template>

<script setup>
import ProfileHeader from "@components/profile/profile-header";

const props = defineProps({
  auction: {
    type: Object,
    required: true
  }
});

const { $dayjs } = useNuxtApp();

const now = useNow();
const finishTime = ref($dayjs(props.auction?.finishAt));
const finished = computed(() => finishTime.value.isBefore(now.value));

</script>
