<template>
  <div class="bg-light w-screen md:max-w-lg h-screen md:h-auto flex flex-col justify-center">
    <ConfirmationCard
      :title="$t('text-ad-delete-title')"
      :description="store?.product?.name || ''"
      :cancel-btn-loading="loading"
      :confirm-btn-loading="loading"
      :on-cancel="handleCancel"
      :on-confirm="handleSubmit"
    />
  </div>
</template>

<script setup>
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected'
import ConfirmationCard from "@components/common/confirmation-card";

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const store = useSelectedStore();
const loading = ref(false);

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleSubmit = async () => {
  loading.value = true;
  const result = await productDelete(store.product.id);
  if (result === true) {
    $toast.success(t('success.product.delete'));
    closeModal();
    await navigatorTo(translatePath(ROUTES.CODE.ADS_MY));
  } else {
    loading.value = false;
    if (import.meta.client) {
      $toast.error(t('error.product.delete'));
    }
    sentryCaptureEvent({
      message: 'confirm-delete-product.handleSubmit',
      level: 'error',
      extra: { error: result }
    });
  }
}

const handleCancel = () => {
  closeModal();
}

</script>
