import _ from 'lodash-es';

export default async function (to, options = {}) {
  if (_.isUndefined(to) || (_.isObject(to) && _.isUndefined(_.get(to, 'path'))) || to === undefined || to === 'undefined') {
    sentryCaptureEvent({
        message: 'navigator-to.undefined',
        level: 'error',
        extra: { to }
    });

    return await navigateTo('/');
  } else {
    return await navigateTo(to, options);
  }
}
