export const emptyAddress = {
  id: null,
  idCountry: null,
  idCalling: null,
  country: {
    id: null,
    code: '',
    name: ''
  },
  calling: {
    id: null,
    code: '',
    name: ''
  },
  firstName: '',
  lastName: '',
  namePrefix: '',
  nameSuffix: '',
  company: '',
  companyPerson: '',
  companyNo: '',
  companyVatNo: '',
  street: '',
  streetNo: '',
  houseNo: '',
  city: '',
  zip: '',
  latitude: 0,
  longitude: 0,
  email: '',
  phone: ''
}
