import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';

export default function (parameters = []) {
  const enums = useEnumStore();
  const route = useRoute();

  const values = _.chain(route.query)
    .cloneDeep()
    .transform((result, value, key) => {
      if (/^p\[\d+\]$/.test(key)) {
        result[key] = _.chain(value).split(',').map(v => _.isString(v) && !isNaN(v) ? parseInt(v) : v).value();
      } else {
        result[key] = null;
      }
    })
    .omitBy(_.isNull)
    .values()
    .flatten()
    .value();

  return _.map(_.cloneDeep(enums.parameters), item => ({
    idParameter: item.id,
    idValue: _.get(_.find(parameters || [], { idParameter: item.id }), 'idValue', _.get(_.find(parameters || [], { parameter: { id: item.id } }), 'value.id', null)),
    name: item.name,
    isRequired: item.isRequired,
    values: _.map(item.values || [], value => ({ id: value.id, name: value.value, value: _.includes(values, value.id) })),
    idCategories: item.idCategories
  }));
}
