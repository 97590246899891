<template>
  <Modal :open="open" :close-class-name="['PRODUCT_DETAILS', 'CONTENT_DETAILS', 'NEWS_DETAILS'].includes(view) ? 'text-light' : 'text-dark'">
    <Login v-if="view === 'LOGIN_VIEW'" />
    <Register v-if="view === 'REGISTER'" />
    <ForgotPassword v-if="view === 'FORGOT_VIEW'" />
    <RecommendProductModalView v-if="view === 'RECOMMEND_PRODUCT'" />
    <ProductDetailsModalView v-if="view === 'PRODUCT_DETAILS'" />
    <ContentDetailsModalView v-if="view === 'CONTENT_DETAILS'" />
    <NewsDetailsModalView v-if="view === 'NEWS_DETAILS'" />
    <ReferenceDetailsModalView v-if="view === 'REFERENCE_DETAILS'" />
    <ProfileCard
      v-if="view === 'SHOP_INFO'"
      :supplier="store.supplier"
      card-class-name="!hidden"
      class-name="!flex flex-col !w-screen !h-screen !rounded-none"
    />
    <CancelOrder v-if="view === 'CANCEL_ORDER'" />
    <VerifiedAccountRequiredNotice v-if="view === 'VERIFIED_REQUIRED_FOR_PUBLISHING'" title="text-verified-user-required-for-ad-publishing" content="text-save-ad-and-publish-later" />
    <VariantImageDescriptionEdit v-if="view === 'EDIT_VARIANT_IMAGE'" />
    <SupplierImageDescriptionEdit v-if="view === 'EDIT_SUPPLIER_IMAGE'" />
    <CartAddedView v-if="view === 'CART_ADDED_VIEW'" />
    <CartRemovedView v-if="view === 'CART_REMOVED_VIEW'" />
    <ConfirmDeleteAccount v-if="view === 'CONFIRM_DELETE_ACCOUNT'" />
    <ConfirmDeleteProduct v-if="view === 'CONFIRM_DELETE_PRODUCT'" />
    <ConfirmDeleteSupplierImage v-if="view === 'CONFIRM_DELETE_SUPPLIER_IMAGE'" />
    <ConfirmAcceptOrder v-if="view === 'CONFIRM_ACCEPT_ORDER'" />
    <ConfirmRefuseOrder v-if="view === 'CONFIRM_REFUSE_ORDER'" />
    <ConfirmBlockUser v-if="view === 'CONFIRM_BLOCK_USER'" />
    <OrderRatingView v-if="view === 'ORDER_RATING_VIEW'" />
    <OrderRatingEdit v-if="view === 'ORDER_RATING_EDIT'" />
    <ProfileChangePassword v-if="view === 'CHANGE_PASSWORD'" class="md:max-w-md" />
    <ReportSuspiciousVariant v-if="view === 'REPORT_SUSPICIOUS_VARIANT'" />
    <ReportSuspiciousContent v-if="view === 'REPORT_SUSPICIOUS_CONTENT'" />
    <ShareLinkModal v-if="view === 'SHARE_LINK_MODAL'" />
    <LocationMapModal v-if="view === 'LOCATION_MAP_MODAL'" />
  </Modal>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import { useUIStore } from '@stores/ui';
import { siteSettings } from "@settings/site.settings";
import Modal from "@components/ui/modal/modal";
import Login from "@components/auth/login";
import Register from "@components/auth/register";
import ForgotPassword from "@components/auth/forgot-password/forgot-password";
import RecommendProductModalView from "@components/product/recommend-product-modal-view";
import ProductDetailsModalView from "@components/product/product-details-modal-view";
import ContentDetailsModalView from "@components/content/content-details-modal-view";
import NewsDetailsModalView from "@components/news/news-details-modal-view";
import ReferenceDetailsModalView from "@components/reference/reference-details-modal-view";
import ProfileCard from "@components/profile/profile-card";
import CancelOrder from "@components/checkout/cancel-order";
import VerifiedAccountRequiredNotice from "@components/account/verified-account-required-notice";
import VariantImageDescriptionEdit from "@components/product/product-edit/variant-image-description-edit";
import SupplierImageDescriptionEdit from "@components/profile/supplier-image-description-edit";
import CartAddedView from "@components/cart/cart-added-view";
import CartRemovedView from "@components/cart/cart-removed-view";
import ConfirmDeleteAccount from "@components/account/confirm-delete-account";
import ConfirmDeleteProduct from "@components/product/confirm-delete-product";
import ConfirmDeleteSupplierImage from "@components/profile/confirm-delete-supplier-image";
import ConfirmAcceptOrder from "@components/order/confirm-accept-order";
import ConfirmRefuseOrder from "@components/order/confirm-refuse-order";
import ConfirmBlockUser from "@components/order/confirm-block-user";
import OrderRatingView from "@components/order/order-rating/order-rating-view";
import OrderRatingEdit from "@components/order/order-rating/order-rating-edit";
import ProfileChangePassword from "@components/profile/profile-change-password";
import ReportSuspiciousVariant from "@components/product/report-suspicious-variant";
import ReportSuspiciousContent from "@components/content/report-suspicious-content";
import ShareLinkModal from "@components/common/share/share-link-modal";
import LocationMapModal from "@components/common/maps/location-map-modal";

const gtm = useGtm();
const open = useState('modal.open', () => false);
const view = useState('modal.view', () => '');

const route = useRoute();
const routeLang = useRouteLang();
const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const ui = useUIStore();

const pushLink = (type) => {
  const lang = routeLang.code.value;
  const section = ROUTES[lang] || ROUTES[siteSettings.language.code];
  const prefix = lang === siteSettings.language.code ? '' : `/${lang}`;
  let url = `${prefix}${section['HOME']}`;
  let name = `${type} [Modal]`;
  if (type === 'LOGIN_VIEW') {
    url = `${prefix}${section['LOGIN']}`;
  } else if (type === 'REGISTER') {
    url = `${prefix}${section['REGISTER']}`;
  } else if (type === 'FORGOT_VIEW') {
    url = `${prefix}${section['FORGOT_PASSWORD']}`;
  } else if (type === 'RECOMMEND_PRODUCT') {
    url = `${prefix}${section['PRODUCT']}/${slugify(store.product?.id, store.product?.name)}#recommend`;
    name = `${store.product?.name} [Recommend Modal]`;
  } else if (type === 'PRODUCT_DETAILS') {
    url = `${prefix}${section['PRODUCT']}/${slugify(store.product?.id, store.product?.name)}`;
    name = `${store.product?.name} [Detail Modal]`;
  } else if (type === 'CONTENT_DETAILS') {
    url = `${prefix}${section['INSPIRATION']}/${slugify(store.content?.id, store.content?.name)}`;
    name = `${store.content?.name} [Detail Modal]`;
  } else if (type === 'NEWS_DETAILS') {
    url = `${prefix}${section['NEWS']}/${slugify(store.content?.id, store.content?.name)}`;
    name = `${store.content?.name} [Detail Modal]`;
  } else if (type === 'REFERENCE_DETAILS') {
    url = `${prefix}${section['REFERENCE']}/${slugify(store.content?.id, store.content?.name)}`;
    name = `${store.content?.name} [Reference Modal]`;
  } else if (type === 'SHOP_INFO') {
    url = `${prefix}${section['SUPPLIER']}/${slugify(store.supplier?.id, store.supplier?.name)}`;
    name = `${store.supplier?.name} [Detail Modal]`;
  } else if (type === 'CANCEL_ORDER') {
    url = `${prefix}${section['CHECKOUT']}/${store.cart?.id}#cancel`;
    name = `${store.cart?.supplier?.name} [Cancel Cart]`;
  } else if (type === 'VERIFIED_REQUIRED_FOR_PUBLISHING') {
    url = store.product?.id
      ? `${prefix}${section['AD_EDIT']}/${store.product?.id}#n-unverified`
      : `${prefix}${section['AD_ADD']}#n-unverified`;
    name = `${store.product?.name} [Publish Alert]`;
  } else if (type === 'EDIT_VARIANT_IMAGE') {
    url = store.product?.id
      ? `${prefix}${section['AD_EDIT']}/${store.product?.id}#i-${store.image?.id}`
      : `${prefix}${section['AD_ADD']}#i-${store.image?.id}`;
    name = `${store.product?.name} [Image Edit]`;
  } else if (type === 'EDIT_SUPPLIER_IMAGE') {
    url = `${prefix}${section['PROFILE']}#i-${store.image?.id}`;
  } else if (type === 'CART_ADDED_VIEW') {
    url = `${prefix}${section['CHECKOUT']}#a-${store.cart?.id}`;
  } else if (type === 'CONFIRM_DELETE_ACCOUNT') {
    url = `${prefix}${section['PROFILE']}#delete`;
  } else if (type === 'CONFIRM_DELETE_PRODUCT') {
    url = `${prefix}${section['AD_DELETE']}/${store.product?.id}`;
  } else if (type === 'CONFIRM_DELETE_SUPPLIER_IMAGE') {
    url = `${prefix}${section['PROFILE']}#i-${store.image?.id}`;
  } else if (type === 'CONFIRM_ACCEPT_ORDER') {
    url = `${prefix}${section['SALES']}/${store?.order?.idCart}#s-accept`;
  } else if (type === 'CONFIRM_REFUSE_ORDER') {
    url = `${prefix}${section['SALES']}/${store?.order?.idCart}#s-refuse`;
  } else if (type === 'CONFIRM_BLOCK_USER') {
    url = `${prefix}${section['SALES']}/${store?.order?.idCart}#s-block`;
  } else if (type === 'ORDER_RATING_VIEW') {
    url = `${prefix}${section['RATE_CART']}/${store.order?.id}`;
  } else if (type === 'ORDER_RATING_EDIT') {
    url = `${prefix}${section['RATE_CART']}/${store.order?.id}`;
  } else if (type === 'CHANGE_PASSWORD') {
    url = `${prefix}${section['CHANGE_PASSWORD']}`;
  } else if (type === 'REPORT_SUSPICIOUS_VARIANT') {
    url = `${prefix}${section['PRODUCT']}/${slugify(store.product?.id, store.product?.name)}#report`;
    name = `${store.product?.name} [Report Modal]`;
  } else if (type === 'REPORT_SUSPICIOUS_CONTENT') {
    url = `${prefix}${section['INSPIRATION']}/${slugify(store.content?.id, store.content?.name)}#report`;
    name = `${store.content?.name} [Report Modal]`;
  } else if (type === 'SHARE_LINK_MODAL') {
    url = `${prefix}${section['PRODUCT']}/${slugify(store.product?.id, store.product?.name)}#share`;
    name = `${store.product?.name} [Share Link Modal]`;
  } else if (type === 'LOCATION_MAP_MODAL') {
    url = `${route.path}#map`;
    if (store.action === 'RETURN_TO_PRODUCT_MODAL') {
      url = `${prefix}${section['PRODUCT']}/${slugify(store.product?.id, store.product?.name)}#map`;
    } else if (store.action === 'RETURN_TO_CONTENT_MODAL') {
      url = `${prefix}${section['INSPIRATION']}/${slugify(store.content?.id, store.content?.name)}#map`;
    }
    name = `${store.product?.name} [Location Map Modal]`;
  }
  if (import.meta.client && url) {
    if (!ui.displayModal && !ui.displaySidebar) {
      store.resetStack();
    }
    store.addToStack(url);
    window.history.pushState({}, '', url);
    try {
      gtm.trackView(name, url);
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

const pullLink = () => {
  if (store.stack?.length) {
    store.removeFromStack();
  }
  if (store.stack?.length) {
    window.history.replaceState({}, '', store.lastInStack() || translatePath(ROUTES.CODE.HOME));
  } else {
    window.history.replaceState({}, '', translatePath(ROUTES.CODE.HOME));
  }
}

const openModal = (value) => {
  ui.setModalView(view.value = value);
  ui.setDisplayModal(open.value = true);
  try {
    gtm.trackEvent({
      event: `modal.open.${value}`,
      category: 'modal',
      action: 'open',
      label: `Opened modal window: ${value}`,
      value: 0,
      noninteraction: false,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

const closeModal = () => {
  try {
    gtm.trackEvent({
      event: `modal.close.${view.value}`,
      category: 'modal',
      action: 'close',
      label: `Closed modal window: ${view.value}`,
      value: 0,
      noninteraction: false,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
  ui.setDisplayModal(open.value = false);
  ui.setModalView(view.value = '');
}

$eventBus.on('modal:open', (value) => {
  pushLink(value);
  openModal(value);
});

$eventBus.on('modal:close', () => {
  if (open.value) {
    closeModal();
    pullLink();
  }
});

onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('popstate', () => {
      if (open.value) {
        closeModal();
      }
    });
  }
});

onUnmounted(() => {
  $eventBus.off('modal:open');
  $eventBus.off('modal:close');
});

</script>
