export default defineNuxtPlugin(() => {
  let navigationCounter = ref(0);
  const router = useRouter();

  router.afterEach((to, from) => {
    if (from?.path !== to?.path && to.fullPath.indexOf('?redirect_uri=') > 0) {
      navigationCounter.value++;
    }
  });

  return {
    provide: { navigationCounter },
  };
});
