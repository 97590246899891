
import * as ipxRuntime$MeIvqMBk2A from '/home/aquarist/www/prod/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1920,
    "2xl": 3840
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "googleusercontent.com",
    "graph.facebook.com",
    "res.cloudinary.com",
    "s3.amazonaws.com",
    "18.141.64.26",
    "via.placeholder.com",
    "pickbazarlaravel.s3.ap-southeast-1.amazonaws.com",
    "picsum.photos",
    "lh3.googleusercontent.com",
    "aquarist.cz.ofvyvoj.cz",
    "backend.foraquarist.com",
    "for-aquarist.com",
    "localhost"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$MeIvqMBk2A, defaults: {"clearCache":false,"maxAge":31536000,"sharp":{"quality":75,"progressive":true,"animated":true,"limitInputPixels":3686400}} }
}
        