import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import { useCustomerStore } from '@stores/customer';

export default async function (error, options = {}) {
  const route = useRoute();
  const auth = useAuthStore();
  const cart = useCartStore();
  const customer = useCustomerStore();

  if (error?.data?.data?.code === 1042 && error?.data?.data?.message && error?.data?.data?.message.includes('Visitor')) {
    cart.reset();
    customer.reset();
    auth.reset();
    if (options?.redirect) {
      await navigatorTo(options.redirect, { external: !_.startsWith(options.redirect, '/') });
    } else {
      await navigatorTo({ path: translatePath(ROUTES.CODE.LOGIN), query: { redirect_uri: route.path } });
    }
  }
}
