<template>
    <span
      :class="[ 'flex flex-row space-x-1 items-center text-xs px-2 py-1 rounded whitespace-nowrap', variantClasses[variant] ]"
    >
        <component :is="icon" v-if="icon" class="w-4 h-4" />
        <span>{{ text }}</span>
    </span>
</template>

<script setup>

const variantClasses = {
  info: "bg-blue-100 text-blue-500",
  warning: "bg-yellow-100 text-yellow-500",
  error: "bg-red-100 text-red-500",
  success: "bg-green-100 text-accent",
  infoOutline: "border border-blue-200 text-blue-600",
  warningOutline: "border border-yellow-200 text-yellow-600",
  errorOutline: "border border-red-200 text-red-600",
  successOutline: "border border-green-200 text-green-600",
};

defineProps({
    icon: {
        type: Object,
        default: () => null
    },
    text: {
        type: String,
        default: '',
    },
    variant: {
        type: String,
        default: 'info' // "info" | "warning" | "error" | "success" | "infoOutline" | "warningOutline" | "errorOutline" | "successOutline"
    }
})

</script>
