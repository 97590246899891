<template>
  <button
    v-if="accent"
    :disabled="disabled"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-primary-dark rounded-full transition-colors bg-accent-dark hover:bg-accent-dark-hover focus:text-accent-dark-hover focus:bg-primary-dark-hover focus:ring-primary-dark-hover"
    @click="onClick"
  >
    {{ $t('text-edit') }}
  </button>
  <button
    v-else
    :disabled="disabled"
    class="w-fit px-6 h-10 flex items-center justify-center text-xs font-bold text-accent-dark rounded-full transition-colors border border-primary-dark bg-primary-dark hover:bg-transparent focus:bg-transparent hover:text-primary-dark-hover focus:text-primary-dark-hover"
    @click="onClick"
  >
    {{ $t('text-edit') }}
  </button>
</template>

<script setup>
import { ROUTES } from "@utils/routes";

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  accent: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});

const { $eventBus } = useNuxtApp();

const onClick = async () => {
  $eventBus.emit('modal:close');
  await navigatorTo(translatePath(ROUTES.CODE.AD_EDIT, props.data?.id));
};

</script>
