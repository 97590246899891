import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { CATEGORY_KEY } from "@utils/constants";

export const useCategoryStore = defineStore(CATEGORY_KEY, () => {
  const loading = useState('categories.loading', () => false);
  const setLoading = (value) => {
    loading.value = value;
  }
  
  const loaded = useState('categories.loaded', () => false);
  const setLoaded = (value) => {
    loaded.value = value;
  }
  
  const error = useState('categories.error', () => false);
  const setError = (value) => {
    error.value = value;
  }
  
  const eshop = useState('categories.eshop', () => []);
  const setEshop = (value) => {
    eshop.value = value;
  }
  const findEshop = (value) => {
    return _.find(eshop.value, value) ?? _.find(_.flatMap(eshop.value, (item) => item?.categories || []), value);
  }
  
  const content = useState('categories.content', () => []);
  const setContent = (value) => {
    content.value = value;
  }
  const findContent = (value) => {
    return _.find(content.value, value) ?? _.find(_.flatMap(content.value, (item) => item?.categories || []), value);
  }
  
  const news = useState('categories.news', () => []);
  const setNews = (value) => {
    news.value = value;
  }
  const findNews = (value) => {
    return _.find(news.value, value) ?? _.find(_.flatMap(news.value, (item) => item?.categories || []), value);
  }
  
  return { 
    loading, setLoading,
    loaded, setLoaded,
    error, setError,
    eshop, setEshop, findEshop,
    content, setContent, findContent,
    news, setNews, findNews
  }
})
