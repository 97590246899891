<template>
  <div class="w-fit flex flex-row items-start mr-12 mb-2 group" :data-m-id="`m-${item.id}`">
    <div :class="[ sameSupplier ? 'w-10 h-10 group-hover:hidden' : '' ]" />
    <div :class="[ sameSupplier ? 'hidden group-hover:block' : '' ]">
      <Avatar
        v-if="!_.isEmpty(item?.supplier) && item?.supplier?.name"
        :src="item?.supplier?.logo"
        class="cursor-pointer"
        :title="item?.supplier?.name"
        loading="lazy"
        @click="navigate"
      />
      <Avatar
        v-else
        :title="`${item?.customer?.firstName} ${item?.customer?.lastName}`.trim()"
      />
    </div>
    <MessageBubble
      :item="item"
      :selected="selected"
      :is-read="isRead"
      :class="[ 'ml-2', item.id === selected || !isRead ? 'bg-accent-dark' : 'bg-chat-message text-white', sameSupplier ? 'rounded-xl' : 'rounded-b-xl rounded-r-xl' ]"
      position="left"
      @read="emitRead"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import Avatar from "@components/ui/avatar";
import MessageBubble from "@components/chat/message-bubble";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  prevItem: {
    type: Object,
    default: () => {}
  },
  selected: {
    type: Number,
    default: null
  },
  isRead: {
    type: Boolean,
    default: true
  }
});

const { $eventBus } = useNuxtApp();

const emit = defineEmits(['read']);

const sameSupplier = computed(() => (props.item?.supplier && props.item?.supplier?.id === props.prevItem?.supplier?.id)
  || (props.item?.customer && props.item?.customer?.id === props.prevItem?.customer?.id)
);

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const navigate = async () => {
  closeModal();
  await navigatorTo(translatePath(ROUTES.CODE.SUPPLIER, slugify(props.item?.supplier?.id, props.item?.supplier?.name)));
}

const emitRead = () => {
  if (!props.item?.isRead) {
    emit('read', props.item.id);
  }
}

</script>
