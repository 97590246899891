export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1 maximum-scale=1"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"theme-color","content":"#ef404a"},{"property":"og:title","content":"ForAquarist.com"},{"property":"og:description","content":"Získejte parťáka pro vaši akvarijní rybku"},{"property":"og:image","content":"/banner/aquarist.jpg"},{"property":"og:type","content":"website"}],"link":[{"rel":"icon","href":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"rel":"preconnect","href":"https://c.clarity.ms"},{"rel":"preconnect","href":"https://www.clarity.ms"},{"rel":"preconnect","href":"https://www.gstatic.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://www.google.com"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s | ForAquarist.com"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'